import { Button, Flex, Layout, Menu, Switch } from "antd";
// 导航栏数据
import styles from "./index.module.scss";
import useAction from "./hooks";
import { Outlet, useNavigate } from "react-router-dom";
import { SettingOutlined } from "@ant-design/icons";
import { SettingModal } from "@/components";
import imageTitleNight from "@/assets/image/m_cad.png";
import imageTitleLight from "@/assets/image/m_cad_grey.png";

const { Header, Content, Sider } = Layout;

const Home: React.FC = () => {
  const {
    mytheme,
    settingOpen,
    settingData,
    selectKey,
    menuData,
    themeChange,
    setSettingOpen,
    getSettingData,
  } = useAction();
  const navigate = useNavigate();

  return (
    <Layout hasSider className={styles["root"]}>
      <Sider className={styles["side"]}>
        <Header className={styles["sideHeader"]}>
          <img
            src={mytheme ? imageTitleLight : imageTitleNight}
            alt="图标"
            className={styles["imgTitle"]}
          />
          {/* <span>AIGC</span> */}
        </Header>
        <Flex justify="center" gap={20} align="center">
          <Switch
            checkedChildren="灯光"
            unCheckedChildren="夜晚"
            className={`${styles["swich"]} ${mytheme && styles["swich-light"]}`}
            checked={mytheme}
            onClick={themeChange}
          />
          <Button
            type="text"
            icon={<SettingOutlined />}
            onClick={() => setSettingOpen(true)}
          />
        </Flex>
        <Menu
          forceSubMenuRender
          mode="inline"
          expandIcon={<></>}
          className={styles["side-content"]}
          items={menuData}
          selectedKeys={selectKey}
          openKeys={menuData.map((item) => item.key)}
          onSelect={({ key }) => {
            navigate(key);
          }}
        />
      </Sider>
      <Layout className={styles["content"]} style={{ marginLeft: "200px" }}>
        <Content className={styles["contentBody"]}>
          <div>
            <Outlet />
          </div>
        </Content>
      </Layout>
      <SettingModal
        open={settingOpen}
        onClose={() => setSettingOpen(false)}
        data={settingData?.data.result}
        onOk={() => getSettingData()}
      />
    </Layout>
  );
};

export default Home;
