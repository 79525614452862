import dayjs from "dayjs";
import { AudioListProp } from "./props";

const useAction = (
  audioList: AudioListProp[],
  audioListChange: (newAudioList: AudioListProp[]) => void
) => {
  // 检测当前卡片时间和前一个卡片时间是否冲突
  const checkTime = (start: string, index: number) => {
    if (index > 0) {
      const date1 = dayjs(start, "HH:mm:ss");
      const date2 = dayjs(audioList[index - 1].end, "HH:mm:ss");

      if (date1 < date2) {
        return false;
      }
    }
    return true;
  };

  // 开始和结束时间不能相同
  const timeChange = (timerString: string[], index: number) => {
    let start = timerString[0];
    let end = timerString[1];
    let state = true;

    if (start === end) {
      end = dayjs(end, "HH:mm:ss").add(1, "second").format("HH:mm:ss");
    }

    state = checkTime(start, index);
    audioListChange(
      audioList.map((citem, cindex) => {
        if (cindex === index) {
          return { ...citem, start: start, end: end, state: state };
        }
        return citem;
      })
    );
  };

  // 编辑文本框
  const textChange = (id: number, text: string) => {
    const tempAudioList = audioList.map((item) => {
      if (item.id === id) {
        return { ...item, text };
      }
      return item;
    });
    audioListChange(tempAudioList);
  };

  // 删除配音项
  const delAudio = (id: number) => {
    audioListChange(audioList.filter((item) => item.id !== id));
  };

  return {
    timeChange,
    textChange,
    delAudio,
  };
};

export default useAction;
