import { FC } from "react";
import { Navigate, RouteProps } from "react-router-dom";

export const Check: FC<RouteProps> = (props) => {
  const token = window.localStorage.getItem("access_token");

  if (token) {
    return props.element as React.ReactElement;
  } else {
    return <Navigate to="/login" />;
  }
};
