import { FC } from "react";
import { Link } from "react-router-dom";
import { Flex, Upload } from "antd";
import {
  ArrowLeftOutlined,
  InboxOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { ButtonSelf } from "@/components";
import styles from "./index.module.scss";
import useAction from "./hooks";
import TimeCard from "../time-card";
import SiderButton from "@/components/SiderButton";
import VideoRate from "@/components/VideoRate";
import { downFile } from "@/utils/util";

const { Dragger } = Upload;

const VideoPreview: FC = () => {
  const {
    myTheme,
    loadingGenerate,
    uploadProps,
    audioList,
    open,
    videoDubbingAudioData,
    updateDubbingDataLoading,
    generateVideo,
    audioListChange,
    generateCheck,
    setOpen,
    getDubbingData,
    getVideoData,
    updateCurrentDubbingData,
  } = useAction();

  return (
    <>
      <div className={styles["content"]}>
        {/* 头部 */}
        <div className={styles["header"]}>
          <Link
            className={styles["button-back"]}
            to={"/CreateVideoDubbingRoute/videoDubbing"}
          >
            <ArrowLeftOutlined
              className={`${
                myTheme
                  ? styles["text-color-light"]
                  : styles["text-color-night"]
              }`}
            />
          </Link>
          <Flex gap={10}>
            <ButtonSelf
              flag={true}
              loading={updateDubbingDataLoading}
              onClick={() => updateCurrentDubbingData()}
            >
              保存
            </ButtonSelf>
            <ButtonSelf
              flag={true}
              icon={<DownloadOutlined />}
              onClick={() => downFile(videoDubbingAudioData.srt_file)}
            >
              字幕
            </ButtonSelf>
          </Flex>
        </div>

        {/* 中间 */}
        <div className={styles["time-card"]}>
          {/* 时间卡片 */}
          <TimeCard
            videoName={videoDubbingAudioData.name}
            audioList={audioList}
            audioListChange={audioListChange}
          />
          {/* 右侧边栏 */}
          <div className={styles["side"]}>
            <div className={styles["file"]}>
              <Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined
                    className={`${!myTheme && styles["text-color-night"]}`}
                  />
                </p>
                <p className={styles["upload-text"]}>上传视频（.mp4/.wav）</p>
                <p className={styles["upload-hint"]}>点击或直接拖放文件</p>
              </Dragger>
            </div>
            <div className={styles["footer"]}>
              <ButtonSelf
                btnName="生成视频"
                flag={generateCheck()}
                loading={loadingGenerate}
                onClick={generateVideo}
              />
            </div>
          </div>
        </div>
      </div>
      <SiderButton onClick={() => setOpen(true)} position={{ top: 20 }} />
      <VideoRate
        title="创建视频生成任务"
        open={open}
        onClose={() => setOpen(false)}
        defaultValue="video"
        page="videoDubbingAudioTask"
        onVideoChange={getVideoData}
        onAudioChange={getDubbingData}
      />
    </>
  );
};

export default VideoPreview;
