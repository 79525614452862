import { useEffect, useState } from "react";
import { useRequest, useUnmount } from "ahooks";
import { useNavigate } from "react-router-dom";
import { postAudioApi } from "@/services";
import { useAigcVideoStore, useVideoStore } from "@/store";
import { message } from "antd";
import { useVideoGenerateStateStore } from "@/store/videoGenerateStateLocal";
import { AudioDubbingProps } from "@/components/VideoRate/props";

interface languageNumProp {
  value: number | undefined;
  valueList: (string | number)[] | undefined;
}

let voiceList: (string | number)[] = [];

const useAction = () => {
  // 视频列表
  const [setTextVideoList] = useAigcVideoStore((state) => [
    state.setTextVideoList,
  ]);

  // 字幕列表
  const [videoDubbingAudioData, settingStyleData, setVideoDubbingAudioData] =
    useVideoStore((state) => [
      state.videoDubbingAudioData,
      state.settingStyleData,
      state.setVideoDubbingAudioData,
    ]);

  // 视频配音字幕任务列表
  const [videoDubbingAudioTask, setVideoDubbingAudioTask] =
    useVideoGenerateStateStore((state) => [
      state.videoDubbingAudioTask,
      state.setVideoDubbingAudioTask,
    ]);

  const [text, textAction] = useState<string>(() =>
    videoDubbingAudioData.audioText !== undefined
      ? videoDubbingAudioData.audioText
      : ""
  );
  const [languageNum, languageNumAction] = useState<languageNumProp>({
    value: settingStyleData.dubbingTask.dubbing_voice[1],
    valueList: settingStyleData.dubbingTask.dubbing_voice,
  });
  const [speechRate, speechRateAction] = useState<number>(
    settingStyleData.dubbingTask.dubbing_speaking_speed
  );
  const [loading, loadingAction] = useState(false);
  const [videoName, videoNameAction] = useState<string>(
    videoDubbingAudioData.name ? videoDubbingAudioData.name : ""
  );
  const [tasksOpen, setTasksOpen] = useState(false);
  const navigate = useNavigate();

  const onChangeTextArea = (value: string) => {
    textAction(value);
  };

  const onChangeLanguage = (valueList?: (string | number)[]) => {
    let value: number | undefined = undefined;
    if (valueList) {
      value = Number(valueList[valueList.length - 1]);
    }
    languageNumAction(() => ({ value, valueList }));
  };

  const onChangeSpeakingSpeed = (value: number) => {
    speechRateAction(value);
  };

  // 检查配置项
  const generateCheck = () => {
    return (
      !!videoName &&
      !!text &&
      typeof languageNum !== "undefined" &&
      typeof speechRate !== "undefined"
    );
  };

  const generateAudio = () => {
    loadingAction(true);
    const params = {
      text: text,
      voice: languageNum?.value as number,
      speech_rate: speechRate as number,
    };
    if (languageNum?.valueList) {
      voiceList = languageNum.valueList;
    }

    generateAudioRequest.run(params);
  };

  const generateAudioRequest = useRequest(postAudioApi, {
    manual: true,
    onSuccess(result) {
      loadingAction(false);
      message.success("成功生成！");

      setVideoDubbingAudioTask({
        ...videoDubbingAudioTask,
        dubbing: {
          ...videoDubbingAudioTask.dubbing,
          tasks: [
            ...videoDubbingAudioTask.dubbing.tasks,
            {
              id: result.data?.task_id,
              name: videoName,
              isGenerate: false,
              state: 0,
              time: result.data.task_time,
              voiceList,
            },
          ],
        },
      });
    },
    onError() {
      loadingAction(false);
      message.error("生成失败！");
    },
  });

  // 视频与字幕生成
  const getDubbingData = (
    name: string,
    data: AudioDubbingProps,
    voiceList: (string | number)[],
    task_id: string,
    status: number
  ) => {
    setVideoDubbingAudioData({
      ...videoDubbingAudioData,
      name,
      voiceList,
      voice: data.voice,
      speech_rate: data.speech_rate,
      source: data.subtitles,
      srt_file: data.srt_file,
      taskMsg: {
        task_id,
        status,
      },
    });

    navigate("/CreateVideoDubbingRoute/videoDubbing/videoPreview");
  };

  const getVideoData = (url: string, name: string) => {
    setTextVideoList(url, name);
  };

  useUnmount(() => {
    setVideoDubbingAudioData({
      ...videoDubbingAudioData,
      name: videoName,
      audioText: text,
    });
  });

  useEffect(() => {
    languageNumAction({
      value: settingStyleData.dubbingTask.dubbing_voice[1],
      valueList: settingStyleData.dubbingTask.dubbing_voice,
    });
    speechRateAction(settingStyleData.dubbingTask.dubbing_speaking_speed);
  }, [settingStyleData.dubbingTask]);

  return {
    text,
    loading,
    tasksOpen,
    videoName,
    languageNum,
    speechRate,
    videoNameAction,
    onChangeTextArea,
    onChangeLanguage,
    onChangeSpeakingSpeed,
    generateAudio,
    generateCheck,
    setTasksOpen,
    getVideoData,
    getDubbingData,
  };
};

export default useAction;
