import { FC, useEffect, useState } from "react";

interface GnerateTimeProps {
  time?: number;
}

const GnerateTime: FC<GnerateTimeProps> = ({ time }) => {
  const [taskTime, setTaskTime] = useState(() => {
    if (time === undefined) {
      return 1;
    }
    return time;
  });

  useEffect(() => {
    let currentTime = 0;
    const timer = setInterval(() => {
      if (taskTime <= 1) {
        clearInterval(timer);
        return;
      }
      ++currentTime;
      if (currentTime >= 60) {
        setTaskTime(taskTime - 1);
        currentTime = 0;
      }
    }, taskTime * 60);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return <div>{taskTime}分钟</div>;
};

export default GnerateTime;
