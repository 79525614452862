import { memo, FC, useState } from "react";
import { Radio, Modal, Input } from "antd";
import { DeleteOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";
import styles from "./index.module.scss";
import { IViewCard } from "./ViewCard.props";

export const ViewCard: FC<IViewCard> = memo(
  ({
    onCheckedChange,
    onDelete,
    onInputText,
    videoSrc,
    imageSrc,
    isExpand,
    iconExpand,
    checkComponent,
  }) => {
    const [checked, setChecked] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isInput, setIsInput] = useState(false);
    const [inputText, setInputText] = useState("");

    const onRadioChange = (e?: React.MouseEvent) => {
      if (!checkComponent) {
        setChecked(!checked);
      }
      if (iconExpand) {
        e?.stopPropagation();
        setIsModalOpen(true);
      }

      onCheckedChange?.(!checked);
    };

    const onExpand = (e: any) => {
      e.stopPropagation();
      isExpand && setIsModalOpen(true);
      !isExpand && onRadioChange();
    };

    const onPressEnter = () => {
      onInputText?.(inputText);
      setIsInput(false);
    };

    const editInput = (e: any) => {
      e.stopPropagation();
      setIsInput(true);
    };

    const renderCheckComponent = () => {
      return checkComponent ? checkComponent : <Radio checked={checked} />;
    };

    const onDel = (e: React.MouseEvent) => {
      e.stopPropagation();
      onDelete && onDelete();
    };

    return (
      <>
        <div
          className={styles["card-body"]}
          //  iconExpand控制只用左上角按钮打开对话框
          onClick={iconExpand ? () => {} : onExpand}
        >
          <div
            className={styles["overlay"]}
            style={{
              backgroundColor: checked ? "rgba(0, 0, 0, 0.3)" : "",
            }}
          ></div>
          {onCheckedChange && (
            <div
              className={
                checkComponent
                  ? styles["card-radio-selfIcon"]
                  : styles["card-radio"]
              }
              onClick={(e) => onRadioChange(e)}
            >
              {renderCheckComponent()}
            </div>
          )}
          {onDelete && (
            <div className={styles["card-delete"]} onClick={onDel}>
              <DeleteOutlined style={{ color: "red", fontSize: "16px" }} />
            </div>
          )}
          {videoSrc && <video src={videoSrc} className={styles["video"]} />}
          {imageSrc && (
            <img
              alt="这是一张图片"
              className={styles["image"]}
              src={imageSrc}
              loading="lazy"
            />
          )}
          {onInputText && (
            <div className={styles["input-text-container"]}>
              {!isInput ? (
                <div className={styles["input-icon-describe"]}>
                  <EditOutlined
                    className={styles["input-text-icon"]}
                    onClick={editInput}
                  />
                  <div
                    className={styles["input-text-describe"]}
                    onClick={editInput}
                    title={inputText}
                  >
                    {inputText}
                  </div>
                </div>
              ) : (
                <Input
                  autoFocus
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  className={styles["input-text"]}
                  placeholder="描述！"
                  onPressEnter={onPressEnter}
                  onBlur={onPressEnter}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              )}
            </div>
          )}
        </div>
        <Modal
          wrapClassName={styles["wrap"]}
          classNames={{
            content: styles["modal-content"],
            body: styles["modal-body"],
          }}
          closeIcon={false}
          centered
          // 销毁防止视频继续播放
          destroyOnClose
          open={isModalOpen}
          footer={null}
          width={""}
          zIndex={10}
          onCancel={() => {
            setIsModalOpen(false);
          }}
        >
          <div
            className={styles["icon-close"]}
            style={{ display: isModalOpen ? "" : "none" }}
            onClick={() => setIsModalOpen(false)}
          >
            <CloseOutlined />
          </div>
          {videoSrc && (
            <video
              autoPlay
              controls
              src={videoSrc}
              style={{ maxHeight: "80vh" }}
            />
          )}
          {imageSrc && (
            <img
              alt="这是一张图片"
              src={imageSrc}
              style={{ maxHeight: "60vh" }}
            />
          )}
        </Modal>
      </>
    );
  }
);
