const limitNum = 150;

// 文本检测修改
export const checkText = (value: string) => {
  // 文本可能为undefined
  if (value?.length) {
    // 先处理句号的换行
    let text = value;
    text = editTextPeriod(text);

    const textArr = text.split("\n");
    const textArrLen = textArr.length;

    // 再分割数组，处理一句话的切割问题
    const resultTextArr = textArr.map((item, index) => {
      let tempItem = editTextCharacters(item);
      // 处理最后一个字符串元素
      if (index !== textArrLen - 1 && tempItem[tempItem.length - 1] !== "\n") {
        tempItem += "\n";
      }
      return tempItem;
    });
    return resultTextArr.join("");
  }
  return value;
};

// 处理句号
const editTextPeriod = (text: string) => {
  const periodRegRex = /[。]/g;
  let tempText = text;
  while (periodRegRex.test(tempText) === true) {
    const index = periodRegRex.lastIndex;
    // 处理句号后是否有换行
    if (tempText[index] !== "\n") {
      tempText = tempText.slice(0, index) + "\n" + tempText.slice(index);
    }
  }

  return tempText;
};

// 处理相关超过特定字数字符，但是没有句号的情况下，根据句子中的特定字符加入换行，让文本分割合理。
const editTextCharacters = (text: string) => {
  const textRegRex = /[.、,，;；!！?？~]/g;
  // 处理英文
  const spaceRex = /[\s]/g;
  let tempText = text;
  const tempTextLen = tempText.length;
  // left和right构成滑动窗口
  // index记录窗口内的特定字符的位置
  let left = 0;
  let right = limitNum;
  let index = limitNum;
  let count = Math.floor(tempTextLen / limitNum);

  while (count > 0) {
    index = limitNum;
    const tempStr = tempText.slice(left, right);

    while (textRegRex.test(tempStr) === true) {
      index = textRegRex.lastIndex;
    }

    // 处理非中文的空格
    if (index === limitNum) {
      while (spaceRex.test(tempStr) === true) {
        index = spaceRex.lastIndex;
      }
    }

    const str = tempText.slice(left + index);
    tempText = tempText.slice(0, left + index) + "\n" + str;
    // 注意字符串新增了一个字符
    left = index + left + 1;
    right = left + limitNum;
    count = Math.floor(str.length / limitNum);
  }

  return tempText;
};

// 检测文本字数是否超出
export const textIsFail = (text: string) => {
  let flag = false;
  if (text.length > limitNum + 10) {
    const textArr = text.split(/[.。\n]/g);
    textArr.forEach((item) => {
      if (item.length > limitNum + 10) {
        flag = true;
      }
    });
  }
  return flag;
};
