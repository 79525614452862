import { FC, useRef, useState } from "react";
import { Button, Card, ConfigProvider, Flex, TimePicker } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import styles from "./index.module.scss";
import useAction from "./hooks";
import { AudioListProp, TempCardProp, TextSelected } from "./props";
import { useAigcVideoStore, useVideoStore } from "@/store";
import {
  ButtonEnSpellRead,
  ButtonNormal,
  ButtonNumContinuouRead,
  ButtonNumSpellRead,
  ButtonNumSpellRead100,
  InputTextMarkDubbing,
} from "@/components";
import ReactQuill from "react-quill";

const TimeCard: FC<TempCardProp> = (props) => {
  const [myTheme] = useAigcVideoStore((state) => [state.myTheme]);

  return (
    <>
      <Card
        className={styles["card-content"]}
        title={<div>{props.videoName}</div>}
        bodyStyle={{
          display: "grid",
          paddingTop: "0",
          paddingBottom: "0",
          gap: "20px",
          maxHeight: "750px",
          overflow: "hidden",
        }}
      >
        <Flex className={styles["card-body"]} vertical gap={20}>
          {props.audioList.map((item, index, arr) => {
            return (
              <CardItem
                key={item.id}
                item={item}
                index={index}
                arr={arr}
                audioList={props.audioList}
                audioListChange={props.audioListChange}
              />
            );
          })}
        </Flex>
        <div className={styles["add-button"]}>
          <Button
            className={`${styles["button"]} ${
              myTheme && styles["button-bgc-light"]
            }`}
            onClick={() => {
              const len = props.audioList.length;
              const lastAudioItem = props.audioList[len - 1];
              const tempAudio = {
                id: lastAudioItem.id + 1,
                start: lastAudioItem.end,
                end: dayjs(lastAudioItem.end, "HH:mm:ss")
                  .add(1, "second")
                  .format("HH:mm:ss"),
                text: "",
                state: true,
              };
              props.audioListChange([...props.audioList, tempAudio]);
            }}
          >
            +
          </Button>
        </div>
      </Card>
    </>
  );
};

interface CardItemProp {
  item: AudioListProp;
  index: number;
  arr: AudioListProp[];
  audioList: AudioListProp[];
  audioListChange: (newAudioList: AudioListProp[]) => void;
}

const CardItem: FC<CardItemProp> = (props) => {
  const [myTheme] = useAigcVideoStore((state) => [state.myTheme]);
  const { item, index, arr, audioList, audioListChange } = { ...props };
  const { timeChange, textChange, delAudio } = useAction(
    audioList,
    audioListChange
  );

  const inputRef = useRef<ReactQuill>(null);
  const [textSelected, textSelectedAction] = useState<TextSelected>();
  const [videoDubbingAudioData] = useVideoStore((state) => [
    state.videoDubbingAudioData,
  ]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Card: {
            headerBg: "red",
            actionsBg: "red",
          },
        },
      }}
    >
      <Card
        // key={item.id}
        type="inner"
        className={`${styles["card-item"]} ${
          myTheme ? styles["card-item-light"] : styles["card-item-night"]
        }`}
        extra={
          <>
            {arr.length !== 1 && (
              <CloseOutlined
                className={styles["delete"]}
                onClick={() => delAudio(item.id)}
              />
            )}
          </>
        }
        title={
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* 时间 */}
              <span>{index + 1}、</span>
              <TimePicker.RangePicker
                allowClear={false}
                inputReadOnly
                status={item.state ? "" : "error"}
                className={`${styles["time-new"]} ${
                  item.state ? "" : styles["time-new-err"]
                }`}
                value={[
                  dayjs(item.start, "HH:mm:ss"),
                  dayjs(item.end, "HH:mm:ss"),
                ]}
                onChange={(time, timeString) => timeChange(timeString, index)}
              />
              {videoDubbingAudioData.voiceList[0] === 0 && (
                <span style={{ display: "flex", gap: 20, marginLeft: 20 }}>
                  <ButtonNormal position={textSelected} refs={inputRef} />
                  <ButtonEnSpellRead position={textSelected} refs={inputRef} />
                  {videoDubbingAudioData.voiceList[1] !== 100 && (
                    <ButtonNumSpellRead
                      position={textSelected}
                      refs={inputRef}
                    />
                  )}
                  {videoDubbingAudioData.voiceList[1] === 100 && (
                    <ButtonNumSpellRead100
                      position={textSelected}
                      refs={inputRef}
                    />
                  )}
                  <ButtonNumContinuouRead
                    position={textSelected}
                    refs={inputRef}
                    disabled={videoDubbingAudioData.voiceList[1] === 100}
                  />
                </span>
              )}
            </div>
          </>
        }
      >
        {/* 文本 */}
        {/* <TextArea
        rows={1}
        value={item.text}
        bordered={false}
        onChange={(e) => editTextArea(item.id, e.target.value)}
      /> */}
        <InputTextMarkDubbing
          ref={inputRef}
          placeholder="输入文本（建议一句话不超过200个字）"
          value={item.text}
          defaultValue={item.text}
          onChange={(value, text) => {
            textChange(item.id, value);
          }}
          onChangeSelection={(selcet) => {
            textSelectedAction(() => selcet);
          }}
        />
      </Card>
    </ConfigProvider>
  );
};

export default TimeCard;
