import {
  VideoCameraOutlined,
  FileImageOutlined,
  TranslationOutlined,
  AudioOutlined,
} from "@ant-design/icons";
import { createElement } from "react";

export const MenuList = [
  {
    label: `文案生成视频`,
    key: "textToVideoRoute",
    icon: createElement(VideoCameraOutlined),
    children: [
      {
        label: "创建生成任务",
        key: "TextToVideoRoute/createTask",
      },
      {
        label: "创建可选任务",
        key: "TextToVideoRoute/createSelectTask",
      },
      {
        label: "创建配音任务",
        key: "CreateVideoDubbingRoute/videoDubbing",
      },
      {
        label: "查看视频列表",
        key: "videoList/videoTextList?status=textVideoList",
      },
    ],
  },
  {
    label: `幻灯片生成视频`,
    key: "imageText",
    icon: createElement(FileImageOutlined),
    children: [
      {
        label: "创建生成任务",
        key: "ImageText/createPPTTask",
      },
      {
        label: "查看视频列表",
        key: "videoList/videoTextList?status=imageVideoList",
      },
    ],
  },
  {
    label: `视频语种翻译`,
    key: "videoTranslate",
    icon: createElement(TranslationOutlined),
    children: [
      {
        label: "创建翻译任务",
        key: "videoTranslate/createSelectTask",
      },
      {
        label: "查看视频列表",
        key: "videoList/videoTextList?status=translateVideoList",
      },
    ],
  },
  {
    label: "文案生成语音",
    key: "aidioText",
    icon: createElement(AudioOutlined),
    children: [
      {
        label: "语音试听",
        key: "aidioTextRoute",
      },
    ],
  },
];
