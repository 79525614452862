import React, { FC, memo } from "react";
import styles from "./index.module.scss";

interface PageAnimationProps {
  children: React.ReactNode;
}

export const PageAnimation: FC<PageAnimationProps> = memo(({ children }) => {
  return <div className={styles["animation"]}>{children}</div>;
});
