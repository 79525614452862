import { Button } from "antd";
import { forwardRef, useEffect, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./index.css";
import { useThemeStyles } from "../theme";
import { ButtonReadProp, InputTextMarkProp } from "./props";
import { changeColor } from "./rich-text-functions";

/***React-Quill设计格式，使用parchment模块自定义格式化，添加特定的颜色***/
const Parchment = Quill.import("parchment");
const Color = new Parchment.Attributor.Style("color", "color", {
  scope: Parchment.Scope.INLINE,
  whitelist: ["rgb(156, 220, 254)", "rgb(0, 177, 74)", "rgb(156, 220, 255)"],
});
Quill.register({ "formats/color": Color }, true);
/***————————***/

export const InputTextMark = forwardRef<ReactQuill, InputTextMarkProp>(
  (props, ref) => {
    const { myTheme } = useThemeStyles();

    return (
      <div className="rich">
        <ReactQuill
          ref={ref}
          theme="snow"
          placeholder={props.placeholder}
          style={{
            height: props.height || 32,
            minHeight: props.height || 32,
            overflowY: "auto",
            resize: "vertical",
          }}
          className={myTheme ? "" : "black"}
          modules={{
            toolbar: false,
          }}
          formats={[]}
          value={props.value}
          onChange={(value, delta, source, editor) => {
            // const newValue = richTextSpace(value);
            props.onChange(value, editor.getText());
          }}
          onChangeSelection={(selection, source, editor) => {
            if (props.onChangeSelection) {
              if (selection?.length !== 0 && selection !== null) {
                const start = selection.index;
                const length = selection.length;
                props.onChangeSelection({
                  text: editor.getText().slice(start, start + length),
                  start,
                  length,
                });
                return;
              }
              props.onChangeSelection(undefined);
            }
          }}
        />
      </div>
    );
  }
);

// react-quill存在value不能直接赋值字符串，使用必须使用defaultValue。而ppt的文本初始化是空的所以可以直接使用上面的文本框
export const InputTextMarkDubbing = forwardRef<ReactQuill, InputTextMarkProp>(
  (props, ref) => {
    const { myTheme } = useThemeStyles();
    const [value, setValue] = useState(props.value);

    useEffect(() => {
      setValue(props.value);
    }, [props.value]);

    return (
      <div className="rich">
        <ReactQuill
          ref={ref}
          theme="snow"
          placeholder={props.placeholder}
          formats={["underline", "color"]}
          modules={{
            toolbar: false,
          }}
          value={value}
          style={{
            height: props.height || 32,
            minHeight: props.height || 32,
            overflowY: "auto",
            resize: "vertical",
          }}
          className={myTheme ? "" : "black"}
          onChange={(value, delta, source, editor) => {
            setValue(value);
            props.onChange(value, editor.getText());
          }}
          onChangeSelection={(selection, source, editor) => {
            if (props.onChangeSelection) {
              if (selection?.length !== 0 && selection !== null) {
                const start = selection.index;
                const length = selection.length;
                props.onChangeSelection({
                  text: editor.getText().slice(start, start + length),
                  start,
                  length,
                });
                return;
              }

              props.onChangeSelection(undefined);
            }
          }}
        />
      </div>
    );
  }
);

export const ButtonNormal = (props: ButtonReadProp) => {
  return (
    <Button
      style={{ width: 90 }}
      onClick={() => changeColor("", false, props.position, props.refs)}
    >
      正常
    </Button>
  );
};

export const ButtonEnSpellRead = (props: ButtonReadProp) => {
  return (
    <Button
      disabled={props.disabled}
      onClick={() =>
        changeColor("rgb(156, 220, 254)", false, props.position, props.refs)
      }
    >
      拼读(英文)
    </Button>
  );
};

export const ButtonNumContinuouRead = (props: ButtonReadProp) => {
  return (
    <Button
      disabled={props.disabled}
      onClick={() =>
        changeColor("rgb(0, 177, 74)", true, props.position, props.refs)
      }
    >
      连读(数字)
    </Button>
  );
};

export const ButtonNumSpellRead = (props: ButtonReadProp) => {
  return (
    <Button
      disabled={props.disabled}
      onClick={() =>
        changeColor("rgb(156, 220, 254)", true, props.position, props.refs)
      }
    >
      拼读(数字)
    </Button>
  );
};

export const ButtonNumSpellRead100 = (props: ButtonReadProp) => {
  return (
    <Button
      disabled={props.disabled}
      onClick={() =>
        changeColor("rgb(156, 220, 255)", true, props.position, props.refs)
      }
    >
      拼读(数字)
    </Button>
  );
};
