import { useEffect, useState } from "react";
import {
  AudioDubbingProps,
  ResultDataProps,
  SelectOptionProps,
  TranslateDubbingProps,
  VideoRateProps,
} from "./props";
import {
  VideoTaskFnType,
  VideoTaskType,
  useVideoGenerateStateStore,
} from "@/store/videoGenerateStateLocal";
import { VIDEO_TASK } from "@/lib/constants";

const useAction = (props: VideoRateProps) => {
  const [
    createPageTask,
    createSelectPageTask,
    videoDubbingAudioTask,
    createPPTPageTask,
    videoTranslateTask,
    setCreatePageTask,
    setCreateSelectPageTask,
    setVideoDubbingAudioTask,
    setCreatePPTPageTask,
    setVideoTranslateTask,
  ] = useVideoGenerateStateStore((state) => [
    state.createPageTask,
    state.createSelectPageTask,
    state.videoDubbingAudioTask,
    state.createPPTPageTask,
    state.videoTranslateTask,
    state.setCreatePageTask,
    state.setCreateSelectPageTask,
    state.setVideoDubbingAudioTask,
    state.setCreatePPTPageTask,
    state.setVideoTranslateTask,
  ]);

  const findData = (pageName: string) => {
    let options: SelectOptionProps[];
    let task: VideoTaskType;
    let setTask: VideoTaskFnType;

    switch (pageName) {
      case "createPageTask":
        options = [VIDEO_TASK[0]];
        task = { ...createPageTask };
        setTask = setCreatePageTask;
        break;

      case "createSelectPageTask":
        options = [VIDEO_TASK[0]];
        task = { ...createSelectPageTask };
        setTask = setCreateSelectPageTask;
        break;

      case "videoDubbingAudioTask":
        options = VIDEO_TASK;
        task = { ...videoDubbingAudioTask };
        setTask = setVideoDubbingAudioTask;
        break;

      case "createPPTPageTask":
        options = [VIDEO_TASK[0]];
        task = { ...createPPTPageTask };
        setTask = setCreatePPTPageTask;
        break;

      case "videoTranslateTask":
        options = VIDEO_TASK;
        task = { ...videoTranslateTask };
        setTask = setVideoTranslateTask;
        break;

      default:
        options = [VIDEO_TASK[0]];
        task = { ...createPageTask };
        setTask = setCreatePageTask;
        break;
    }
    return { options, task, setTask };
  };

  let setTask = findData(props.page).setTask;
  let options = findData(props.page).options;

  const [taskList, setTaskList] = useState(findData(props.page).task);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selectValue, setSelectValue] = useState<string>(
    () => props.defaultValue || options[0].value
  );

  // 视频生成状态
  const generateVideoTask = (data: ResultDataProps) => {
    if ("url" in data.result) {
      if (selectValue in taskList) {
        let newTaskList = JSON.parse(JSON.stringify(taskList));
        let name = "";
        newTaskList[selectValue] = {
          ...taskList[selectValue],
          tasks: taskList[selectValue].tasks.map((item) => {
            if (item.id === data.task_id) {
              name = item.name;
              return { ...item, isGenerate: true };
            }
            return item;
          }),
        };
        setTask(newTaskList);
        setTaskList(newTaskList);

        props.onVideoChange?.(data.result.url, name);
      }
    } else {
      let name = "";
      let voiceList: (string | number)[] = [];
      let videoUrl: string = "";
      let model: string[] = [];
      taskList[selectValue].tasks.some((item) => {
        if (item.id === data.task_id) {
          name = item.name;
          if ("voiceList" in item) {
            voiceList = item?.voiceList as (string | number)[];
          }
          if ("videoUrl" in item) {
            videoUrl = item.videoUrl as string;
          }
          if ("model" in item) {
            model = item.model as string[];
          }
        }
        return item.id === data.task_id;
      });

      if ("new" in data.result.subtitles[0]) {
        props.onTranslateChange &&
          props.onTranslateChange(
            name,
            data.result as TranslateDubbingProps,
            voiceList,
            videoUrl,
            model,
            data.task_id,
            data.status
          );
      } else {
        props.onAudioChange &&
          props.onAudioChange(
            name,
            data.result as AudioDubbingProps,
            voiceList,
            data.task_id,
            data.status
          );
      }
    }
  };

  const onChangeState = (id: string, state: number) => {
    if (selectValue in taskList) {
      const arr = taskList[selectValue].tasks.map((item) => {
        if (item.id === id) {
          return { ...item, state };
        }
        return item;
      });

      let newTaskList = JSON.parse(JSON.stringify(taskList));
      newTaskList[selectValue] = {
        ...newTaskList[selectValue],
        tasks: arr,
      };
      setTask(newTaskList);
      setTaskList(newTaskList);
    }
  };

  const deleteVideoTask = (idList: string[]) => {
    if (selectValue in taskList) {
      const idListSet = new Set(idList);
      let newTaskList = JSON.parse(JSON.stringify(taskList));
      newTaskList[selectValue] = {
        ...taskList[selectValue],
        tasks: taskList[selectValue].tasks.filter(
          (item) => !idListSet.has(item.id)
        ),
      };
      setTask(newTaskList);
      setTaskList(newTaskList);
    }
  };

  const onCheckAllChange = (value: boolean) => {
    if (value) {
      setCheckedList(taskList[selectValue].tasks.map((item) => item.id));
    } else {
      setCheckedList([]);
    }
    setCheckAll(value);
  };

  const onCheckChange = (newCheckedList: string[]) => {
    if (newCheckedList.length === taskList[selectValue].tasks.length) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
    setCheckedList(newCheckedList);
  };

  const deleteTask = () => {
    setDeleteFlag(false);
    onCheckAllChange(false);
    deleteVideoTask(checkedList);
  };

  useEffect(() => {
    onCheckAllChange(false);
  }, [selectValue]);

  useEffect(() => {
    setTaskList(findData(props.page).task);
  }, [
    createPageTask,
    createSelectPageTask,
    videoDubbingAudioTask,
    createPPTPageTask,
    videoTranslateTask,
  ]);

  return {
    checkAll,
    checkedList,
    taskList,
    options,
    deleteFlag,
    selectValue,
    setDeleteFlag,
    onCheckAllChange,
    onCheckChange,
    setSelectValue,
    generateVideoTask,
    onChangeState,
    deleteTask,
  };
};

export default useAction;
