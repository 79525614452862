import dayjs from "dayjs";
import { CardItemProp } from "./props";
import { postTranslateTextApi } from "@/services";
import { useRequest } from "ahooks";

const useAction = (props: CardItemProp) => {
  const { item, audioList, audioListChange } = props;
  const { run: tanslateText } = useRequest(postTranslateTextApi, {
    manual: true,
    debounceWait: 1000,
    onSuccess(result) {
      editNewText(item.id, result.data?.text);
    },
  });

  // 检测当前卡片时间和前一个卡片时间是否冲突
  const checkTime = (start: string, index: number) => {
    if (index > 0) {
      const date1 = dayjs(start, "HH:mm:ss");
      const date2 = dayjs(audioList[index - 1].new.end, "HH:mm:ss");

      if (date1 < date2) {
        return false;
      }
    }
    return true;
  };

  // 开始和结束时间不能相同
  // 新的数据
  const newTimeChange = (timerString: string[], index: number) => {
    let start = timerString[0];
    let end = timerString[1];
    let state = true;

    if (start === end) {
      end = dayjs(end, "HH:mm:ss").add(1, "second").format("HH:mm:ss");
    }

    state = checkTime(start, index);
    audioListChange(
      audioList.map((citem, cindex) => {
        if (cindex === index) {
          return {
            ...citem,
            state: state,
            new: { ...citem.new, start, end },
          };
        }
        return citem;
      })
    );
  };

  // 原始数据
  const oldimeChange = (timerString: string[], index: number) => {
    let start = timerString[0];
    let end = timerString[1];

    if (start === end) {
      end = dayjs(end, "HH:mm:ss").add(1, "second").format("HH:mm:ss");
    }

    audioListChange(
      audioList.map((citem, cindex) => {
        if (cindex === index) {
          return {
            ...citem,
            old: { ...citem.old, start, end },
          };
        }
        return citem;
      })
    );
  };

  // 编辑新的文本框
  const editNewText = (id: number, text: string) => {
    const tempAudioList = audioList.map((item) => {
      if (item.id === id) {
        return { ...item, new: { ...item.new, text } };
      }
      return item;
    });
    audioListChange(tempAudioList);
  };

  // 编辑原始数据文本框
  const editOldTextArea = (id: number, text: string) => {
    const tempAudioList = audioList.map((item) => {
      if (item.id === id) {
        return { ...item, old: { ...item.old, text } };
      }
      return item;
    });
    audioListChange(tempAudioList);
  };

  // 删除配音项
  const delAudio = (id: number) => {
    audioListChange(audioList.filter((item) => item.id !== id));
  };

  return {
    newTimeChange,
    oldimeChange,
    editNewText,
    editOldTextArea,
    delAudio,
    tanslateText,
  };
};

export default useAction;
