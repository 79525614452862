import { Button, ConfigProvider } from "antd";
import styles from "./index.module.scss";
import { BtnSelfProp } from "./props";
import { useThemeStyles } from "../theme";

export const ButtonSelf = (prop: BtnSelfProp) => {
  const { styles: stylesMyTheme } = useThemeStyles();
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            defaultBg: stylesMyTheme["myButtonAllowColor"],
            colorText: "rgb(255,255,255)",
          },
        },
      }}
    >
      <Button
        type="default"
        style={prop.style}
        className={styles["button"]}
        disabled={!prop.flag ? true : false}
        onClick={prop.onClick}
        loading={prop.loading}
        icon={prop.icon}
      >
        {prop.btnName || prop.children}
      </Button>
    </ConfigProvider>
  );
};
