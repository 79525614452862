import { Card, CardProps } from "antd";
import { FC } from "react";
import { useThemeStyles } from "../theme";

export const CardSelf: FC<CardProps> = (props) => {
  const { myTheme } = useThemeStyles();
  return (
    <Card
      {...props}
      headStyle={
        {
          padding: 0,
          borderBottom: `1px solid ${
            myTheme ? "rgb(240,240,240)" : "rgb(100,100,100)"
          }`,
        } || props.headStyle
      }
      bodyStyle={{ padding: 0 } || props.bodyStyle}
    >
      {props.children}
    </Card>
  );
};
