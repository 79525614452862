import { useEffect, useRef, useState } from "react";
import { TableDataProps } from "./props";
import { useRequest } from "ahooks";
import { getVoiceApi, postAudioDataApi } from "@/services";

const useAction = () => {
  const [soundText, setSoundText] = useState<string>(
    "生活就像海洋，只有意志坚强的人才能到达彼岸"
  );
  const [soundSpeed, setSoundSpeed] = useState<number>(0);
  const [tableData, setTableData] = useState<TableDataProps[]>([]);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [listenKey, setListenKey] = useState<React.Key>("");
  const [downKey, setDownKey] = useState<React.Key>("");

  useRequest(getVoiceApi, {
    onSuccess(result) {
      setTableData(result.data?.result);
    },
  });

  const { loading: listenLoading, runAsync: getAudio } = useRequest(
    postAudioDataApi,
    {
      manual: true,
    }
  );

  const { loading: downLoading, runAsync: downAudio } = useRequest(
    postAudioDataApi,
    {
      manual: true,
    }
  );

  const listenAudio = (text: string, voice_id: number, speed: number) => {
    getAudio({
      text,
      voice_id,
      file_format: "wav",
      response_format: "url",
      speed,
    }).then((result) => {
      audioRef.current?.setAttribute("src", result.data?.result);
      audioRef.current?.load();
      audioRef.current?.play().catch((err) => {
        if (err.name === "AbortError") return;
      });
    });
  };

  const downloadAudio = (
    text: string,
    voice_id: number,
    speed: number,
    name: string
  ) => {
    setDownKey(voice_id);
    downAudio({
      text,
      voice_id,
      file_format: "wav",
      response_format: "url",
      speed,
    }).then(({ data }) => {
      fetch(data?.result)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.blob();
        })
        .then((blob) => {
          const urlObject = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = urlObject;
          link.download = `${name}.wav`;
          link.click();
          window.URL.revokeObjectURL(urlObject);
          setDownKey("");
        });
    });
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;
    audio.addEventListener("ended", () => {
      setListenKey("");
    });

    return audio.addEventListener("ended", () => {
      setListenKey("");
    });
  }, []);

  return {
    audioRef,
    soundText,
    tableData,
    soundSpeed,
    listenKey,
    downKey,
    listenLoading,
    downLoading,
    setSoundText,
    setSoundSpeed,
    listenAudio,
    downloadAudio,
    setListenKey,
  };
};

export default useAction;
