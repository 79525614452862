import { useRequest, useUnmount } from "ahooks";
import { useRef, useState } from "react";
import { getVideoSimilarDataApi } from "@/services";
import { TextSelected, dataProp } from "./props";
import { message } from "antd";
import { useAigcVideoStore, useVideoStore } from "@/store";
import ReactQuill from "react-quill";
import { textReplace } from "@/components";

const useAction = () => {
  const [createSelectPageList, setCreateSelectPageList] = useVideoStore(
    (state) => [state.createSelectPageList, state.setCreateSelectPageList]
  );

  // 存储视频
  const [setTextVideoList] = useAigcVideoStore((state) => [
    state.setTextVideoList,
  ]);

  const [videoName, videoNameAction] = useState<string>(
    createSelectPageList.title
  );
  const [inputText, inputTextAction] = useState<string>(
    createSelectPageList.text
  );
  const [videoData, videoDataAction] = useState<dataProp[]>(
    () => createSelectPageList.videoData
  );

  const inputRef = useRef<ReactQuill>(null);
  const [textSelected, textSelectedAction] = useState<TextSelected>();
  const [loading, loadingAction] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  useUnmount(() => {
    setCreateSelectPageList({
      ...createSelectPageList,
      title: videoName,
      text: inputText,
      videoData: videoData,
    });
  });

  const videoDataRequest = useRequest(getVideoSimilarDataApi, {
    manual: true,
    onSuccess(result) {
      loadingAction(false);
      message.success("require success");
      if (result.data.result.length !== 0) {
        videoDataAction([...result.data.result]);
      }
    },
    onError(err) {
      loadingAction(false);
      message.error("require error");
    },
  });

  const generateVideo = () => {
    loadingAction(true);
    videoDataRequest.run({ text: textReplace(inputText), top_k: 10 });
  };

  const deleteVideoData = () => {
    videoDataAction([]);
    inputTextAction("");
  };
  // 检查是否配置必选项
  const generateCheck = () => {
    if (inputText.length && videoName.length) {
      return true;
    }
    return false;
  };

  const onChangeText = (value: string, text: string) => {
    if (text.length - 1 > 200) {
      inputTextAction(inputText);
    } else {
      inputTextAction(value);
    }
  };

  // 视频生成状态
  const generateVideoTask = (url: string, name: string) => {
    setTextVideoList(url, name);
  };

  return {
    inputRef,
    textSelected,
    inputText,
    videoData,
    videoName,
    loading,
    open,
    setOpen,
    onChangeText,
    textSelectedAction,
    deleteVideoData,
    inputTextAction,
    generateVideo,
    videoNameAction,
    generateCheck,
    generateVideoTask,
  };
};

export default useAction;
