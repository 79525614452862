import api from "./api/api";
import { ImageGeneration, VideoTaskMessageProps } from "./api.types";

/**
 * 图片生成视频
 */
export const postImageGeneration2Api = async (params: ImageGeneration) => {
  return await api.post<VideoTaskMessageProps>(
    `/api/video/generation2`,
    params
  );
};
