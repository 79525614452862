import { Route, Routes } from "react-router-dom";
import VideoTextList from "../../pages/video-text-list";
import { Check } from "../Check";

const videoTextList = () => {
  return (
    <Routes>
      <Route
        path="videoTextList"
        element={<Check element={<VideoTextList />} />}
      ></Route>
    </Routes>
  );
};

export default videoTextList;
