import axios from "axios";
import { Env } from "../../config/env";

const createApi = (baseUrl: string, apiKey: string) => {
  const getApi = () => {
    const newApi = axios.create({
      baseURL: baseUrl,
      headers: {
        Accept: "application/json",
        "x-api-key": apiKey,
      },
    });

    newApi.interceptors.request.use((request) => {
      const tokenData = JSON.parse(
        window.localStorage.getItem("access_token") ?? "{}"
      );
      if (tokenData) {
        const token = tokenData.tokenType + " " + tokenData.token;
        request.headers.Authorization = token;
      }
      return request;
    });

    newApi.interceptors.response.use(
      (result) => {
        // if (result.data?.status != 0) {
        //   message.warning(result?.data?.message);
        // }

        return result;
      },
      (error) => {
        if (error?.response?.status === 401) {
          window.localStorage.removeItem("access_token");
          window.location.replace("/login");
        }

        return Promise.reject(error);
      }
    );

    return newApi;
  };
  return getApi();
};

export const audioApi = createApi(Env.audioUrl, Env.audioApiKey);

const api = createApi(Env.url, Env.apiKey);
export default api;
