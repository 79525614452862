import { FC } from "react";
import { Button } from "antd";
import { MenuFoldOutlined } from "@ant-design/icons";
import styles from "./index.module.scss";
import { useThemeStyles } from "../theme";

interface SiderButtonProps {
  onClick: () => void;
  className?: string;
  position?: { top?: number; left?: number };
}

const SiderButton: FC<SiderButtonProps> = (props) => {
  const { myTheme } = useThemeStyles();
  return (
    <span
      style={{ top: props.position?.top, left: props.position?.left }}
      className={`${props.className} ${styles["content"]} ${styles["siderContent"]}`}
    >
      <Button type="text" onClick={props.onClick}>
        <MenuFoldOutlined
          className={`${myTheme ? styles["icon"] : styles["icon-black"]}`}
        />
      </Button>
    </span>
  );
};

export default SiderButton;
