import { FC, useState } from "react";
import { Button, Checkbox, ConfigProvider, Image } from "antd";
import { FullscreenOutlined, DeleteOutlined } from "@ant-design/icons";
import styles from "./index.module.scss";

interface ImageCardProps {
  src: string;
  checked?: boolean;
  onExpand?: () => void;
  onSelect?: (value: boolean) => void;
  onDelete?: () => void;
  expandIcon?: React.ReactNode;
  deleteIcon?: React.ReactNode;
}

const ImageCard: FC<ImageCardProps> = (props) => {
  const [isExpand, setIsExpand] = useState<boolean>(false);
  const [isSelect, setIsSelect] = useState<boolean>(false);

  const onExpand = () => {
    props.onExpand && props.onExpand();
    setIsExpand(true);
  };

  const onSelect = (value: boolean) => {
    props.onSelect && props.onSelect(value);
    setIsSelect(value);
  };

  const onDelete = () => {
    props.onDelete && props.onDelete();
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Checkbox: {
            colorBgContainer: "rgb(255,255,255)",
            colorPrimary: "rgb(100, 200, 0)",
          },
        },
      }}
    >
      <div className={styles["body"]}>
        {props.onExpand && (
          <>
            {props.expandIcon || (
              <Button
                className={styles["icon-expand"]}
                type="text"
                onClick={onExpand}
                icon={
                  <FullscreenOutlined
                    style={{ fontSize: "20px", color: "rgb(105, 4, 233)" }}
                  />
                }
              />
            )}
          </>
        )}
        {props.onDelete && (
          <>
            {props.deleteIcon || (
              <Button
                className={styles["icon-delete"]}
                type="text"
                icon={
                  <DeleteOutlined
                    style={{ color: "red", fontSize: "16px" }}
                    onClick={onDelete}
                  />
                }
              />
            )}
          </>
        )}
        {props.onSelect && (
          <Checkbox
            className={styles["check-box"]}
            checked={props.checked !== undefined ? props.checked : isSelect}
            onChange={(e) => {
              setIsSelect(e.target.checked);
              onSelect(e.target.checked);
            }}
          />
        )}
        <Image
          className={styles["image"]}
          height={200}
          src={props.src}
          loading="lazy"
          preview={{
            minScale: 0.1,
            mask: <></>,
            visible: isExpand,
            maskClassName: styles["image-mask"],
            onVisibleChange: () => setIsExpand(false),
          }}
        />
      </div>
    </ConfigProvider>
  );
};

export default ImageCard;
