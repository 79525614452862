import api from "../api/api";
import {
  GetSettingListApiResponseProps,
  PostSettingListApiProps,
} from "./props";

export const getSettingListApi = async () => {
  return await api.post<GetSettingListApiResponseProps>("/button_style/list");
};

export const postSettingListApi = async (data: PostSettingListApiProps) => {
  return await api.post("/button_style/record", data);
};
