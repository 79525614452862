import { Route, Routes } from "react-router-dom";
import CreateVideoDubbing from "@/pages/video-dubbing";
import VideoPreview from "@/pages/video-dubbing/video-preview";
import TextToAudio from "@/pages/video-dubbing/text-audio";
import { Check } from "../Check";

const CreateVideoDubbingRoute = () => {
  return (
    <Routes>
      <Route path="videoDubbing" element={<CreateVideoDubbing />}>
        <Route index element={<Check element={<TextToAudio />} />} />
        <Route
          path="videoPreview"
          element={<Check element={<VideoPreview />} />}
        />
      </Route>
    </Routes>
  );
};

export default CreateVideoDubbingRoute;
