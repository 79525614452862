import { InboxOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  Divider,
  message,
  Select,
  Upload,
  type UploadProps,
} from "antd";
import {
  ButtonSelf,
  CardSelf,
  CascaderLanguage,
  LanguageSelect,
  SelectSpeakingSpeed,
} from "@/components";
import { Env } from "@/config/env";
import useAction from "./hooks";
import styles from "./index.module.scss";
import SiderButton from "@/components/SiderButton";
import VideoRate from "@/components/VideoRate";
import { useEffect } from "react";

const { Dragger } = Upload;

const VideoUpload = () => {
  const {
    myTheme,
    loading,
    uploadFile,
    open,
    selectValue,
    termSelectOptionsValues,
    videoFile,
    languageNum,
    speechRate,
    termSelectData,
    modelSelectData,
    setTermSelectData,
    setSelectValue,
    setOpen,
    onSelectChange,
    generate,
    videoFileAction,
    onChangeSpeakingSpeed,
    uploadFileAction,
    generateCheck,
    getVideoData,
    getDubbingData,
    setModelSelectData,
  } = useAction();

  const props: UploadProps = {
    name: "upload_file",
    multiple: true,
    accept: ".mp4, .wav",
    action: `${Env.url}/api/file/save_video`,
    headers: {
      "X-API-KEY": Env.apiKey,
      Accept: "application/json",
    },

    defaultFileList: uploadFile,
    beforeUpload(file) {
      let file_name = file.name;
      let num = 0;
      let flag = false;
      videoFile.forEach((item) => {
        const tempReg = new RegExp(`${file_name}\\((\\d+)\\)`);
        if (item.name.search(tempReg) !== -1) {
          const tempNum = item.name.slice(
            file_name.length + 1,
            item.name.length - 1
          );
          num = Math.max(num, Number(tempNum) + 1);
        }
        if (item.name === file_name) {
          flag = true;
        }
      });
      if (flag) {
        if (num === 0) {
          num = 1;
        }
        file_name = file_name + `(${num})`;
      }
      const new_file = new File([file], file_name);
      return Promise.resolve(new_file);
    },
    onChange(info) {
      const { status } = info.file;

      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);

        videoFileAction((per) => {
          return [
            ...per,
            {
              name: `${info.file.name.slice(
                0,
                info.file.name.lastIndexOf(".")
              )}`,
              url: info.file.response.url[0],
            },
          ];
        });
        uploadFileAction(() => [...uploadFile, info.file]);
      } else if (status === "error") {
        // 清空数组，保存状态
        message.error(`${info.file.name} file upload failed.`);
      }

      if (status === "removed") {
        videoFileAction(
          videoFile.filter((item) => item.url !== info.file?.response?.url[0])
        );
        uploadFileAction(
          uploadFile.filter(
            (item) => item.response.url !== info.file?.response?.url
          )
        );
      }
    },
    onDrop(e) {
      // 清空状态数组
      uploadFileAction(() => []);
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };

  useEffect(() => {
    if (
      videoFile.length !== 0 &&
      videoFile.every((item) => item.url !== selectValue)
    ) {
      setSelectValue(videoFile[0].url);
    }
  }, [videoFile, selectValue]);

  return (
    <>
      <div className={styles["root"]}>
        <CardSelf className={`${styles["upload"]}`}>
          <div className={styles["file"]}>
            <Dragger {...props} className={styles["dragger"]}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined
                  className={`${!myTheme && styles["text-color-night"]}`}
                />
              </p>
              <p className={styles["upload-text"]}>上传视频（.mp4/.wav）</p>
              <p className={styles["upload-hint"]}>点击或直接拖放文件</p>
            </Dragger>
          </div>
          <Divider className={styles["divider"]} />
          <div className={styles["btnAll"]}>
            <AutoComplete
              className={styles["name"]}
              bordered={false}
              placeholder="请输入视频名称"
              options={videoFile.map((item) => ({
                label: item.name,
                value: item.url,
              }))}
              value={videoFile.find((item) => item.url === selectValue)?.name}
              onSelect={(value) => {
                setSelectValue(value);
              }}
              onChange={(value, options) => {
                if (!Array.isArray(options)) {
                  if (options.value) {
                    return;
                  } else {
                    videoFileAction(
                      videoFile.map((item) => {
                        if (item.url === selectValue) {
                          return { ...item, name: value };
                        }
                        return item;
                      })
                    );
                  }
                }
              }}
            />
            <div className={styles["btn"]}>
              <LanguageSelect
                value={modelSelectData}
                placeholder="选择模型"
                data={[
                  { label: "通用1", value: 0 },
                  { label: "通用2", value: 1 },
                  { label: "通用3", value: 2 },
                ]}
                onChange={(value) => setModelSelectData(value)}
              />
              <Select
                value={termSelectData}
                placeholder="选择术语库"
                style={{ width: 120 }}
                options={[
                  { label: "无", value: "null" },
                  ...termSelectOptionsValues.map((item) => ({
                    label: item,
                    value: item,
                  })),
                ]}
                onChange={(value) => {
                  setTermSelectData(value);
                }}
              />
              <SelectSpeakingSpeed
                value={speechRate}
                onChange={onChangeSpeakingSpeed}
              />
              <CascaderLanguage
                value={languageNum.valueList}
                onChange={onSelectChange}
              />
              <ButtonSelf
                btnName="生成"
                flag={generateCheck()}
                onClick={generate}
                loading={loading}
              />
            </div>
          </div>
        </CardSelf>
      </div>
      <SiderButton onClick={() => setOpen(true)} />
      <VideoRate
        title="创建视频生成任务"
        open={open}
        onClose={() => setOpen(false)}
        page="videoTranslateTask"
        defaultValue="dubbing"
        onVideoChange={getVideoData}
        onTranslateChange={getDubbingData}
      />
    </>
  );
};

export default VideoUpload;
