import {
  Button,
  Card,
  ConfigProvider,
  Input,
  Table,
  TableColumnsType,
  message,
} from "antd";
import styles from "./index.module.scss";
import useAction from "./hooks";
import { TableDataProps } from "./props";
import {
  PageAnimation,
  SelectSpeakingSpeed,
  useThemeStyles,
} from "@/components";
import { CaretRightOutlined, PauseOutlined } from "@ant-design/icons";

const AudioText = () => {
  const {
    audioRef,
    soundText,
    tableData,
    soundSpeed,
    listenKey,
    downKey,
    listenLoading,
    downLoading,
    setSoundText,
    setSoundSpeed,
    listenAudio,
    downloadAudio,
    setListenKey,
  } = useAction();
  const { myTheme } = useThemeStyles();

  const columns: TableColumnsType<TableDataProps> = [
    { title: "语种", width: 300, dataIndex: "language" },
    { title: "性别", width: 300, dataIndex: "gender" },
    { title: "风格", dataIndex: "comment" },
    {
      title: "操作",
      width: 200,
      render: (value, record) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                if (soundText.trim() === "") {
                  message.warning("请输入文本");
                  return;
                }
                audioRef.current?.pause();

                setListenKey(record.voice_id);
                if (listenKey !== record.voice_id && audioRef.current?.paused) {
                  listenAudio(soundText, record.voice_id, soundSpeed);
                } else {
                  setListenKey("");
                }
              }}
              icon={
                <>
                  {listenKey === record.voice_id && <PauseOutlined />}
                  {listenKey !== record.voice_id && <CaretRightOutlined />}
                </>
              }
              loading={listenKey === record.voice_id && listenLoading}
            >
              试听
            </Button>

            <Button
              type="link"
              loading={downKey === record.voice_id && downLoading}
              onClick={() => {
                if (soundText.trim() === "") {
                  message.warning("请输入文本");
                  return;
                }

                downloadAudio(
                  soundText,
                  record.voice_id,
                  soundSpeed,
                  record.comment
                );
              }}
            >
              下载
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            activeShadow: "",
          },
        },
      }}
    >
      <PageAnimation>
        <Card
          className={styles["body"]}
          bodyStyle={{ height: 840 }}
          title={
            <Input
              className={styles["input"]}
              placeholder="请输入试听文本"
              maxLength={200}
              showCount
              value={soundText}
              onChange={(e) => setSoundText(e.target.value)}
            />
          }
          extra={
            <SelectSpeakingSpeed
              value={soundSpeed}
              onChange={(value) => setSoundSpeed(value)}
            />
          }
        >
          <article>
            {tableData.length !== 0 && (
              <div className={styles["content"]}>
                <Table
                  virtual
                  className={`${!myTheme && styles["table"]}`}
                  rowKey={"voice_id"}
                  columns={columns}
                  dataSource={tableData}
                  scroll={{ y: 670 }}
                />
              </div>
            )}
          </article>
          <audio ref={audioRef} controls hidden>
            您的浏览器不支持 audio 元素。
          </audio>
        </Card>
      </PageAnimation>
    </ConfigProvider>
  );
};

export default AudioText;
