import { Input } from "antd";
import inputTextProp from "./props";
import styles from "./style.module.scss";

const { TextArea } = Input;

// 传入设定好的值和hook
export const InputText = (prop: inputTextProp) => {
  return (
    <TextArea
      style={prop.style ? prop.style : undefined}
      classNames={{
        textarea: ` ${styles["textarea"]} ${
          prop.className ? prop.className : ""
        }`,
      }}
      styles={{
        textarea: {
          borderRadius: "0",
          minHeight: "84px",
        },
      }}
      color="white"
      bordered={false}
      placeholder={prop.placeholder || "输入文本  (建议一句话不超过200个字)"}
      value={prop.value}
      maxLength={prop?.maxLength}
      onChange={({ target }) => prop.onChange(target.value)}
    ></TextArea>
  );
};
