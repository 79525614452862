import { useEffect, useMemo, useState } from "react";
import { useAigcVideoStore } from "@/store";
import { useLocation } from "react-router-dom";

const useAction = () => {
  const [
    myTheme,
    textVideoList,
    imageVideoList,
    translateVideoList,
    delTextVideoList,
    delImageVideoList,
    delTranslateVideoList,
    updateTextVideoList,
    updateImageVideoList,
    updateTranslateVideoList,
  ] = useAigcVideoStore((state) => [
    state.myTheme,
    state.textVideoList,
    state.imageVideoList,
    state.translateVideoList,
    state.delTextVideoList,
    state.delImageVideoList,
    state.delTranslateVideoList,
    state.updateTextVideoList,
    state.updateImageVideoList,
    state.updateTranslateVideoList,
  ]);

  const location = useLocation();
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [videos, setVideos] = useState<{ name: string; url: string }[]>([]);

  const { videoListTemp, delVideoList, updateVideoList } = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    const storeKey = urlParams.get("status") || "textVideoList";
    let videoListTemp;
    let delVideoList: (url: string) => void;
    let updateVideoList: (url: string, name: string) => void;
    switch (storeKey) {
      case "textVideoList":
        videoListTemp = textVideoList;
        delVideoList = delTextVideoList;
        updateVideoList = updateTextVideoList;
        break;
      case "imageVideoList":
        videoListTemp = imageVideoList;
        delVideoList = delImageVideoList;
        updateVideoList = updateImageVideoList;
        break;
      case "translateVideoList":
        videoListTemp = translateVideoList;
        delVideoList = delTranslateVideoList;
        updateVideoList = updateTranslateVideoList;
        break;
      default:
        videoListTemp = textVideoList;
        delVideoList = delTextVideoList;
        updateVideoList = updateTextVideoList;
        break;
    }

    return {
      videoListTemp,
      delVideoList,
      updateVideoList,
    };
  }, [
    location.search,
    textVideoList,
    imageVideoList,
    translateVideoList,
    delTextVideoList,
    delImageVideoList,
    delTranslateVideoList,
    updateTextVideoList,
    updateImageVideoList,
    updateTranslateVideoList,
  ]);

  const [videoList, videoListAction] = useState<any[]>(videoListTemp);
  const [editNameFlag, editNameFlagAction] = useState<boolean[]>(
    videoListTemp.map(() => false)
  );

  const deleteVideo = (url: string) => {
    const arr = videoList.filter((item) => item.url !== url);

    videoListAction(() => arr);
    delVideoList(url);
  };

  //   限制显示的字符串长度
  const reviseVideoName = (name: string, index: number) => {
    if (!name) {
      return `未命名视频${index + 1}`;
    }

    return name;
  };

  //   编辑视频名称
  const inputOnBlur = (url: string, newName: string) => {
    editNameFlagAction(
      editNameFlag.map(() => {
        return false;
      })
    );
    const arr = videoList.map((cItem) => {
      if (cItem.url === url) {
        return { url: url, name: newName };
      }
      return cItem;
    });

    videoListAction(arr);
    updateVideoList(url, newName);
  };

  //   点击图标进去编辑状态
  const editVideoNameClick = (index: number) => {
    editNameFlagAction(
      editNameFlag.map((citem, cindex) => {
        if (cindex === index) {
          return true;
        }
        return citem;
      })
    );
  };

  const downloadVideos = async (list: { name: string; url: string }[]) => {
    const a = document.createElement("a");
    list.forEach(async (item) => {
      const response = await fetch(item.url);
      if (!response.ok) throw new Error(`${item.name} 下载失败`);
      const blob = await response.blob();
      a.href = URL.createObjectURL(blob);
      a.download = `${item.name}.mp4`;
      a.rel = "noopener noreferrer";
      a.click();
    });
  };

  useEffect(() => {
    videoListAction(videoListTemp);
  }, [videoListTemp]);

  return {
    myTheme,
    videoList,
    editNameFlag,
    downloadOpen,
    videos,
    deleteVideo,
    reviseVideoName,
    inputOnBlur,
    editVideoNameClick,
    setDownloadOpen,
    downloadVideos,
    setVideos,
  };
};
export default useAction;
