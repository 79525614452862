import "./App.css";
import { MyConfigProvider } from "@/components";
import { BrowserRouter } from "react-router-dom";
import HomeRoutes from "./routes";

function App() {
  return (
    <MyConfigProvider>
      <div className="App">
        <BrowserRouter>
          <HomeRoutes />
        </BrowserRouter>
      </div>
    </MyConfigProvider>
  );
}

export default App;
