import { VideoTaskMessageProps } from "../api.types";
import api from "../api/api";
import { getVideoDataProp, getVideoSimilarDataProp } from "./props";

// 推荐生成一个视频
export const getVideoDataApi = async (params: getVideoDataProp) => {
  return await api.post<VideoTaskMessageProps>("/api/video/generation", params);
};

// 推荐生成多个视频
export const getVideoSimilarDataApi = async (
  params: getVideoSimilarDataProp
) => {
  return await api.post("/api/video/similarity", params);
};

// 选择视频合成
export const getVideoSimilarDataMergeApi = async (params: getVideoDataProp) => {
  return await api.post<VideoTaskMessageProps>("/api/video/generation", params);
};
