import { message } from "antd";

export const getBase64Image = async (src: string) => {
  return await fetch(src)
    .then((r) => r.arrayBuffer())
    .then((arrBuffer) => {
      const base64 = btoa(
          new Uint8Array(arrBuffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        ),
        img = new Image(),
        source = "data:image/png;base64," + base64;
      img.src = source;
      return source;
    });
};

export const downFile = async (url: string) => {
  if (url === "") {
    message.warning("文件为空");
    return;
  }
  try {
    await fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`下载时发生错误:${response.statusText}`);
        }

        return response.blob();
      })
      .then((blob) => {
        const urlBlob = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.download = "字幕.txt";
        a.href = urlBlob;
        a.click();

        URL.revokeObjectURL(urlBlob);
      });
  } catch (err) {
    message.error(`下载失败:${err}`);
  }
};
