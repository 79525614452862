import { Button, Flex, Form, InputNumber, Modal, Row, Select } from "antd";
import { FC, useEffect } from "react";
import { ButtonSelf } from "@/components";
import { ImageList } from "../props";

interface MaterialModalProps {
  open: boolean;
  data: ImageList[];
  onClose: () => void;
  onOk: (data: ImageList[]) => void;
}

interface FormProps {
  ppt: string;
  image_id: number;
  ratio: number;
  logo_position_weight: number;
  logo_position_height: number;
  copyright_id: number;
  font_size: number;
  copyright_posisition_weight: number;
  copyright_posisition_height: number;
}

const logoControlOPtions = [
  { label: "不添加logo", value: 0 },
  { label: "快享成公司logo", value: 1 },
  { label: "墨西哥公司logo", value: 2 },
];

const copyRightControlOptions = [
  { label: "不添加版权信息", value: 0 },
  { label: "版权信息1", value: 1 },
];

const MaterialModal: FC<MaterialModalProps> = (props) => {
  const [form] = Form.useForm<FormProps>();

  const onMaterialValueChange = (value: string) => {
    const currentData = props.data.find((item) => item.image.url === value);

    form.setFieldsValue({
      ppt: currentData?.image.url,
      image_id: currentData?.image.logo.image_id,
      ratio: currentData?.image.logo.ratio,
      logo_position_weight: currentData?.image.logo.position.weight,
      logo_position_height: currentData?.image.logo.position.height,
      copyright_id: currentData?.image.copy_right.copy_right_id,
      font_size: currentData?.image.copy_right.font_size,
      copyright_posisition_weight:
        currentData?.image.copy_right.position.weight,
      copyright_posisition_height:
        currentData?.image.copy_right.position.height,
    });
  };

  useEffect(() => {
    form.resetFields();
  }, [props.open, form]);

  return (
    <Modal
      title={"素材"}
      maskClosable={false}
      open={props.open}
      onCancel={props.onClose}
      width={700}
      forceRender
      footer={
        <>
          <Button onClick={() => props.onClose()}>取消</Button>
          <ButtonSelf
            style={{ width: 80 }}
            flag={true}
            onClick={() => form.submit()}
          >
            确认
          </ButtonSelf>
        </>
      }
    >
      <Form
        form={form}
        labelAlign="left"
        colon={true}
        initialValues={{
          ppt: props.data?.[0]?.image.url,
          image_id: props.data?.[0]?.image.logo.image_id,
          ratio: props.data?.[0]?.image.logo.ratio,
          logo_position_weight: props.data?.[0]?.image.logo.position.weight,
          logo_position_height: props.data?.[0]?.image.logo.position.height,
          copyright_id: props.data?.[0]?.image.copy_right.copy_right_id,
          font_size: props.data?.[0]?.image.copy_right.font_size,
          copyright_posisition_weight:
            props.data?.[0]?.image.copy_right.position.weight,
          copyright_posisition_height:
            props.data?.[0]?.image.copy_right.position.height,
        }}
        onFinish={(value) => {
          const data = props.data.map((item) => {
            if (item.image.url === value.ppt) {
              return {
                ...item,
                image: {
                  url: item.image.url,
                  logo: {
                    image_id: value.image_id,
                    ratio: value.ratio,
                    position: {
                      weight: value.logo_position_weight,
                      height: value.logo_position_height,
                    },
                  },
                  copy_right: {
                    copy_right_id: value.copyright_id,
                    font_size: value.font_size,
                    position: {
                      weight: value.copyright_posisition_weight,
                      height: value.copyright_posisition_height,
                    },
                  },
                },
              };
            }
            return item;
          });
          props.onOk(data);
        }}
      >
        <Form.Item label="ppt" name="ppt" labelCol={{ span: 3, offset: 2 }}>
          <Select
            maxTagCount="responsive"
            style={{ width: 200 }}
            options={props.data.map((item, index) => {
              return {
                label: index + 1,
                value: item.image.url,
              };
            })}
            onChange={(value) => {
              onMaterialValueChange(value);
            }}
          />
        </Form.Item>

        <Form.Item labelCol={{ span: 24 }} noStyle>
          <Flex vertical gap={10}>
            <div>LOGO信息：</div>
            <Form.Item
              label={"样式"}
              name="image_id"
              labelCol={{ span: 3, offset: 2 }}
            >
              <Select style={{ width: 200 }} options={logoControlOPtions} />
            </Form.Item>
            <Form.Item
              label={"缩放比例"}
              name="ratio"
              labelCol={{ span: 3, offset: 2 }}
            >
              <InputNumber
                style={{ width: 200 }}
                step={0.1}
                min={0.5}
                max={2.5}
              />
            </Form.Item>
            <Form.Item label={"位置"} labelCol={{ span: 3, offset: 2 }}>
              <Row>
                <Form.Item label={"x轴"} name="logo_position_weight">
                  <InputNumber step={0.01} min={0} max={0.9} />
                </Form.Item>

                <Form.Item
                  label={"y轴"}
                  name="logo_position_height"
                  labelCol={{ offset: 10 }}
                >
                  <InputNumber step={0.01} min={0} max={0.9} />
                </Form.Item>
              </Row>
              <div>
                x轴：logo与视频左侧的距离；&nbsp;&nbsp;&nbsp;&nbsp;y轴：logo与视频顶部的距离
              </div>
            </Form.Item>
          </Flex>
        </Form.Item>

        <Form.Item labelCol={{ span: 24 }} noStyle>
          <Flex vertical gap={10}>
            <div>版权信息：</div>
            <Form.Item
              label={"样式"}
              name="copyright_id"
              labelCol={{ span: 3, offset: 2 }}
            >
              <Select
                style={{ width: 200 }}
                options={copyRightControlOptions}
              />
            </Form.Item>
            <Form.Item
              label={"文字大小"}
              name="font_size"
              labelCol={{ span: 3, offset: 2 }}
            >
              <InputNumber style={{ width: 200 }} min={4} max={10} />
            </Form.Item>
            <Form.Item label={"位置"} labelCol={{ span: 3, offset: 2 }}>
              <Row>
                <Form.Item
                  label={"居中偏移值"}
                  name="copyright_posisition_weight"
                >
                  <InputNumber step={0.01} min={-0.2} max={0.2} />
                </Form.Item>

                <Form.Item
                  label={"底部距离"}
                  name="copyright_posisition_height"
                  labelCol={{ offset: 10 }}
                >
                  <InputNumber step={0.01} min={0} max={0.5} />
                </Form.Item>
              </Row>
            </Form.Item>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default MaterialModal;
