import { FC } from "react";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined, DownloadOutlined } from "@ant-design/icons";
import { ButtonSelf, SelectSubtitle } from "@/components";
import styles from "./index.module.scss";
import useAction from "./hooks";
import TimeCard from "../time-card";
import SiderButton from "@/components/SiderButton";
import VideoRate from "@/components/VideoRate";
import { downFile } from "@/utils/util";

const TranslateSubtitle: FC = () => {
  const {
    myTheme,
    loadingGenerate,
    subtitleList,
    selectValue,
    subtitle,
    videoTranslateData,
    open,
    updateDubbingDataLoading,
    setOpen,
    generateVideo,
    audioListChange,
    tanslateSubtitleChange,
    generateCheck,
    getVideoData,
    getDubbingData,
    updateCurrentDubbingData,
  } = useAction();

  return (
    <>
      <div className={styles["content"]}>
        {/* 头部 */}
        <div className={styles["header"]}>
          <Link
            className={styles["button-back"]}
            to={"/videoTranslate/createSelectTask"}
          >
            <ArrowLeftOutlined
              className={`${
                myTheme
                  ? styles["text-color-light"]
                  : styles["text-color-night"]
              }`}
            />
          </Link>
          <div className={styles["button"]}>
            <SelectSubtitle
              onChange={tanslateSubtitleChange}
              value={selectValue}
              subtitle={subtitle}
            />
            <ButtonSelf
              flag={true}
              loading={updateDubbingDataLoading}
              onClick={() => updateCurrentDubbingData()}
            >
              保存
            </ButtonSelf>
            <ButtonSelf
              flag={true}
              icon={<DownloadOutlined />}
              onClick={() => downFile(videoTranslateData.srt_file)}
            >
              字幕
            </ButtonSelf>
            <ButtonSelf
              btnName="生成"
              flag={generateCheck()}
              loading={loadingGenerate}
              onClick={generateVideo}
            />
          </div>
        </div>
        {/* 时间卡片 */}
        <TimeCard
          videoName={videoTranslateData.name}
          audioList={subtitleList}
          audioListChange={audioListChange}
        />
      </div>
      <SiderButton onClick={() => setOpen(true)} />
      <VideoRate
        title="创建视频生成任务"
        open={open}
        onClose={() => setOpen(false)}
        page="videoTranslateTask"
        defaultValue="video"
        onVideoChange={getVideoData}
        onTranslateChange={getDubbingData}
      />
    </>
  );
};

export default TranslateSubtitle;
