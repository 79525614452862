import { useEffect, useState } from "react";
import { message } from "antd";
import { useRequest, useUnmount } from "ahooks";
import { useNavigate } from "react-router-dom";
import {
  postTranslateLingoSelectApi,
  postTranslateVideoUploadApi,
} from "@/services";
import { useAigcVideoStore, useVideoStore } from "@/store";
import { languageNumProp } from "./props";
import { TranslateDubbingProps } from "@/components/VideoRate/props";
import { useVideoGenerateStateStore } from "@/store/videoGenerateStateLocal";

let voiceList: (string | number)[] = [];

const useAction = () => {
  // 主题颜色
  const [myTheme] = useAigcVideoStore((state) => [state.myTheme]);

  const [setTranslateVideoList] = useAigcVideoStore((state) => [
    state.setTranslateVideoList,
  ]);

  // 字幕
  const [videoTranslateData, settingStyleData, setVideoTranslateData] =
    useVideoStore((state) => [
      state.videoTranslateData,
      state.settingStyleData,
      state.setVideoTranslateData,
    ]);

  // 存储视频进度列表
  const [videoTranslateTask, setVideoTranslateTask] =
    useVideoGenerateStateStore((state) => [
      state.videoTranslateTask,
      state.setVideoTranslateTask,
    ]);

  const [videoFile, videoFileAction] = useState<
    { name: string; url: string }[]
  >(videoTranslateData.currentFile);
  const [uploadFile, uploadFileAction] = useState<any[]>(
    videoTranslateData.uploadFile
  );
  const [loading, loadingAction] = useState<boolean>(false);
  const [selectValue, setSelectValue] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [termSelectOptionsValues, setTermSelectOptionsValues] = useState<
    string[]
  >([]);

  const [languageNum, languageNumAction] = useState<languageNumProp>({
    value:
      settingStyleData.translateTask.translate_voice[
        settingStyleData.translateTask.translate_voice.length - 1
      ],
    valueList: settingStyleData.translateTask.translate_voice,
  });
  const [speechRate, speechRateAction] = useState<number>(
    settingStyleData.translateTask.translate_speaking_speed
  );
  const [termSelectData, setTermSelectData] = useState<string>(
    settingStyleData.translateTask.translate_term
  );

  const [modelSelectData, setModelSelectData] = useState<number>(
    settingStyleData.translateTask.translate_model
  );

  const navigate = useNavigate();

  const { run: getTermData } = useRequest(postTranslateLingoSelectApi, {
    manual: true,
    onSuccess(result) {
      setTermSelectOptionsValues(result.data.model);
    },
  });

  // 检查配置
  const generateCheck = () => {
    if (
      !!videoFile.length &&
      typeof languageNum !== "undefined" &&
      typeof speechRate !== "undefined" &&
      typeof modelSelectData !== "undefined"
    ) {
      return true;
    }
    return false;
  };

  const onChangeSpeakingSpeed = (value: number) => {
    speechRateAction(value);
  };

  const onSelectChange = (valueList?: (string | number)[]) => {
    let value: number | undefined = undefined;
    if (valueList) {
      value = Number(valueList[valueList.length - 1]);
    }
    languageNumAction(() => ({ value, valueList }));
  };

  // 生成视频
  const generate = () => {
    loadingAction(true);
    if (languageNum?.valueList) {
      voiceList = languageNum?.valueList;
    }

    generateRequest.run({
      source: videoFile,
      voice: languageNum?.value as number,
      speech_rate: speechRate as number,
      model: termSelectData === "null" ? [] : [termSelectData],
      provider_id: 1,
      asr_provider_id: modelSelectData as number,
    });
  };

  const generateRequest = useRequest(postTranslateVideoUploadApi, {
    manual: true,
    onSuccess(result) {
      loadingAction(false);
      message.success("成功生成!");

      setVideoTranslateTask({
        ...videoTranslateTask,
        dubbing: {
          ...videoTranslateTask.dubbing,
          tasks: [
            ...videoTranslateTask.dubbing.tasks,
            ...result.data.result.map((item) => ({
              id: item.task_id,
              name: item.name,
              isGenerate: false,
              state: result.data.status ?? 0,
              time: item.task_time,
              voiceList,
              videoUrl: item.url,
              model: termSelectData === "null" ? [] : [termSelectData],
            })),
          ],
        },
      });
    },
    onError(e) {
      loadingAction(false);
      message.error("生成失败!");
    },
  });

  const getDubbingData = (
    name: string,
    data: TranslateDubbingProps,
    voiceList: (string | number)[],
    videoUrl: string,
    model: string[],
    task_id: string,
    status: number
  ) => {
    setVideoTranslateData({
      ...videoTranslateData,
      name,
      voiceList,
      dubbingVideoUrl: videoUrl,
      voice: data.voice,
      speech_rate: data.speech_rate,
      result_subtitle: data.subtitles,
      srt_file: data.srt_file,
      model: model,
      taskMsg: {
        task_id: task_id,
        status: status,
      },
    });

    navigate("/videoTranslate/createSelectTask/adjustSubtitle");
  };

  const getVideoData = (url: string, name: string) => {
    setTranslateVideoList(url, name);
  };

  // 页面销毁缓存
  useUnmount(() => {
    setVideoTranslateData({
      ...videoTranslateData,
      currentFile: videoFile,
      uploadFile: uploadFile,
    });
  });

  useEffect(() => {
    getTermData({ provider_id: 1 });
  }, []);

  useEffect(() => {
    languageNumAction({
      value:
        settingStyleData.translateTask.translate_voice[
          settingStyleData.translateTask.translate_voice.length - 1
        ],
      valueList: settingStyleData.translateTask.translate_voice,
    });
    speechRateAction(settingStyleData.translateTask.translate_speaking_speed);
    setTermSelectData(settingStyleData.translateTask.translate_term);
    setModelSelectData(settingStyleData.translateTask.translate_model);
  }, [settingStyleData.translateTask]);

  return {
    myTheme,
    loading,
    uploadFile,
    open,
    selectValue,
    termSelectOptionsValues,
    videoFile,
    languageNum,
    speechRate,
    termSelectData,
    modelSelectData,
    setTermSelectData,
    setSelectValue,
    setOpen,
    onSelectChange,
    generate,
    videoFileAction,
    onChangeSpeakingSpeed,
    uploadFileAction,
    generateCheck,
    getVideoData,
    getDubbingData,
    setModelSelectData,
  };
};

export default useAction;
