import { FC, useEffect } from "react";
import styles from "./index.module.scss";
import { useRequest } from "ahooks";
import { generateVideoApi } from "@/services/generate-video-api";
import { ResultDataProps } from "../props";

interface PollingItemProps {
  state: string;
  className: string;
  id: string;
  onChangeState: (id: string, state: number) => void;
}

const PollingItem: FC<PollingItemProps> = (props) => {
  const { run, cancel } = useRequest(generateVideoApi, {
    manual: true,
    pollingInterval: 5000,
    pollingWhenHidden: false,
    onSuccess(result) {
      const tempData: ResultDataProps = result.data;
      props.onChangeState(tempData.task_id, tempData.status);
      if (tempData.status === 2 || tempData.status === 3) {
        cancel();
      }
    },
    onError() {
      cancel();
    },
  });

  useEffect(() => {
    run({ task_id: props.id });
  }, [props.id]);

  return (
    <>
      <span className={styles[props.className]}>{props.state}</span>
    </>
  );
};

export default PollingItem;
