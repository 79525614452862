import { Route, Routes } from "react-router-dom";
import VideoTranslate from "@/pages/Video-Translate";
import VideoUpload from "@/pages/Video-Translate/video-upload";
import TranslateSubtitle from "@/pages/Video-Translate/subtitles-change";
import { Check } from "../Check";

const TranslateVideo = () => {
  return (
    <Routes>
      <Route path="createSelectTask" element={<VideoTranslate />}>
        <Route index element={<Check element={<VideoUpload />} />} />
        <Route
          path="adjustSubtitle"
          element={<Check element={<TranslateSubtitle />} />}
        />
      </Route>
    </Routes>
  );
};

export default TranslateVideo;
