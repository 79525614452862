import { Button, Flex, Form, Input, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import styles from "./index.module.scss";
import { useRequest } from "ahooks";
import { loginApi } from "@/services/login";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { run } = useRequest(loginApi, {
    manual: true,
    onSuccess(result) {
      localStorage.setItem(
        "access_token",
        JSON.stringify({
          token: result.data.access_token,
          tokenType: "Bearer",
        })
      );

      navigate("/");
    },
    onError() {
      message.error("账户或密码错误");
    },
  });

  return (
    <div className={styles["root"]}>
      <div className={styles["login"]}>
        <Form
          form={form}
          onFinish={(value) => {
            run({
              username: value.username,
              password: value.password,
            });
          }}
        >
          <Flex vertical gap={20}>
            <Flex justify="center">
              <div className={styles["title"]}>LOGIN</div>
            </Flex>
            <div>
              <Form.Item
                name="username"
                rules={[{ required: true, message: "请输入用户名" }]}
              >
                <Input prefix={<UserOutlined />} placeholder="用户名" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required: true, message: "请输入密码" }]}
              >
                <Input.Password prefix={<LockOutlined />} placeholder="密码" />
              </Form.Item>
            </div>

            <Form.Item>
              <Button className={styles["btn"]} htmlType="submit">
                登录
              </Button>
            </Form.Item>
          </Flex>
        </Form>
      </div>
    </div>
  );
};

export default Login;
