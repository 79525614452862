export const initialMaterialData = {
  url: "",
  logo: {
    image_id: 0,
    ratio: 1.5,
    position: {
      weight: 0.85,
      height: 0.05,
    },
  },
  copy_right: {
    copy_right_id: 0,
    font_size: 6,
    position: {
      weight: 0,
      height: 0.1,
    },
  },
};
