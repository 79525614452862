// 视频人声
export const LANGUAGE_DATA = [
  {
    label: "粤语",
    value: 0,
    children: [
      { label: "XiaoMin", value: 110 },
      { label: "YunSong", value: 111 },
      { label: "HiuMaan", value: 112 },
      { label: "WanLung", value: 113 },
      { label: "HiuGaai", value: 114 },
      { label: "shanshan", value: 311 },
      { label: "jiajia", value: 312 },
      { label: "taozi", value: 313 },
      { label: "kelly", value: 314 },
    ],
  },
  {
    label: "普通话",
    value: 1,
    children: [
      { label: "Xiaoxiao", value: 120 },
      { label: "Yunxi", value: 121 },
      { label: "Yunjian", value: 122 },
      { label: "Xiaoyi", value: 123 },
      { label: "Yunyang", value: 124 },
      { label: "Xiaochen", value: 125 },
      { label: "Xiaohan", value: 126 },
      { label: "Xiaomeng", value: 127 },
      { label: "Xiaomo", value: 128 },
      { label: "Xiaoqiu", value: 129 },
      { label: "zhixiaobai", value: 321 },
      { label: "zhixiaoxia", value: 322 },
      { label: "zhixiaomei", value: 323 },
      { label: "zhigui", value: 324 },
      { label: "zhishuo", value: 325 },
      { label: "aixia", value: 326 },
      { label: "xiaoyun", value: 327 },
      { label: "xiaogang", value: 328 },
      { label: "ruoxi", value: 329 },
    ],
  },
  {
    label: "英语",
    value: 2,
    children: [
      { label: "Jenny", value: 130 },
      { label: "Guy", value: 131 },
      { label: "Aria", value: 132 },
      { label: "Davis", value: 133 },
      { label: "Amber", value: 134 },
      { label: "Andrew", value: 135 },
      { label: "Ashley", value: 136 },
      { label: "Brandon", value: 137 },
      { label: "Brian", value: 138 },
      { label: "Christopher", value: 139 },
      { label: "annie", value: 331 },
      { label: "betty", value: 332 },
      { label: "beth", value: 333 },
      { label: "cindy", value: 334 },
      { label: "cally", value: 335 },
      { label: "donna", value: 336 },
      { label: "eva", value: 337 },
      { label: "brian", value: 338 },
      { label: "william", value: 339 },
    ],
  },
  {
    label: "日语",
    value: 3,
    children: [
      { label: "Nanami", value: 140 },
      { label: "Keita", value: 141 },
      { label: "Aoi", value: 142 },
      { label: "Daichi", value: 143 },
      { label: "Mayu", value: 144 },
      { label: "Naoki", value: 145 },
      { label: "Shiori", value: 146 },
      { label: "tomoka", value: 341 },
      { label: "tomoya", value: 342 },
    ],
  },
  {
    label: "韩语",
    value: 4,
    children: [
      { label: "SunHi", value: 150 },
      { label: "InJoon", value: 151 },
      { label: "BongJin", value: 152 },
      { label: "GookMin", value: 153 },
      { label: "Hyunsu", value: 154 },
      { label: "JiMin", value: 155 },
      { label: "SeoHyeon", value: 156 },
      { label: "SoonBok", value: 157 },
      { label: "YuJin", value: 158 },
      { label: "Kyong", value: 351 },
    ],
  },
  {
    label: "西班牙语",
    value: 5,
    children: [
      { label: "Elvira", value: 160 },
      { label: "Alvaro", value: 161 },
      { label: "Abril", value: 162 },
      { label: "Arnau", value: 163 },
      { label: "Dario", value: 164 },
      { label: "Elias", value: 165 },
      { label: "Estrella", value: 166 },
      { label: "Irene", value: 167 },
      { label: "Laia", value: 168 },
      { label: "Lia", value: 169 },
      { label: "camila", value: 361 },
    ],
  },
  {
    label: "法语",
    value: 6,
    children: [
      { label: "Denise", value: 171 },
      { label: "clara", value: 371 },
    ],
  },
];

// 不标记需要添加的语种
export const LANGUAGE_DATA_part = [
  // {
  //   label: "粤语",
  //   value: 0,
  //   children: [],
  // },
  {
    label: "普通话",
    value: 1,
    children: [
      { label: "alloy", value: 221 },
      { label: "echo", value: 222 },
      { label: "fable", value: 223 },
      { label: "onyx", value: 224 },
      { label: "nova", value: 225 },
      { label: "shimmer", value: 226 },
    ],
  },
  {
    label: "英语",
    value: 2,
    children: [
      { label: "alloy", value: 231 },
      { label: "echo", value: 232 },
      { label: "fable", value: 233 },
      { label: "onyx", value: 234 },
      { label: "nova", value: 235 },
      { label: "shimmer", value: 236 },
    ],
  },
  {
    label: "日语",
    value: 3,
    children: [
      { label: "alloy", value: 241 },
      { label: "echo", value: 242 },
      { label: "fable", value: 243 },
      { label: "onyx", value: 244 },
      { label: "nova", value: 245 },
      { label: "shimmer", value: 246 },
    ],
  },
  {
    label: "韩语",
    value: 4,
    children: [
      { label: "alloy", value: 251 },
      { label: "echo", value: 252 },
      { label: "fable", value: 253 },
      { label: "onyx", value: 254 },
      { label: "nova", value: 255 },
      { label: "shimmer", value: 256 },
    ],
  },
  {
    label: "西班牙语",
    value: 5,
    children: [
      { label: "alloy", value: 261 },
      { label: "echo", value: 262 },
      { label: "fable", value: 263 },
      { label: "onyx", value: 264 },
      { label: "nova", value: 265 },
      { label: "shimmer", value: 266 },
    ],
  },
  {
    label: "法语",
    value: 6,
    children: [
      { label: "alloy", value: 271 },
      { label: "echo", value: 272 },
      { label: "fable", value: 273 },
      { label: "onyx", value: 274 },
      { label: "nova", value: 275 },
      { label: "shimmer", value: 276 },
    ],
  },
];

const VOICE_TPYE = [
  {
    label: "100",
    value: 100,
    children: [
      {
        label: "粤语",
        value: 0,
        children: [
          { label: "XiaoMin", value: 110 },
          { label: "YunSong", value: 111 },
          { label: "HiuMaan", value: 112 },
          { label: "WanLung", value: 113 },
          { label: "HiuGaai", value: 114 },
          // { label: "shanshan", value: 311 },
          // { label: "jiajia", value: 312 },
          // { label: "taozi", value: 313 },
          // { label: "kelly", value: 314 },
        ],
      },
      {
        label: "普通话",
        value: 1,
        children: [
          { label: "Xiaoxiao", value: 120 },
          { label: "Yunxi", value: 121 },
          { label: "Yunjian", value: 122 },
          { label: "Xiaoyi", value: 123 },
          { label: "Yunyang", value: 124 },
          { label: "Xiaochen", value: 125 },
          { label: "Xiaohan", value: 126 },
          { label: "Xiaomeng", value: 127 },
          { label: "Xiaomo", value: 128 },
          { label: "Xiaoqiu", value: 129 },
          // { label: "zhixiaobai", value: 321 },
          // { label: "zhixiaoxia", value: 322 },
          // { label: "zhixiaomei", value: 323 },
          // { label: "zhigui", value: 324 },
          // { label: "zhishuo", value: 325 },
          // { label: "aixia", value: 326 },
          // { label: "xiaoyun", value: 327 },
          // { label: "xiaogang", value: 328 },
          // { label: "ruoxi", value: 329 },
        ],
      },
      {
        label: "英语",
        value: 2,
        children: [
          { label: "Jenny", value: 130 },
          { label: "Guy", value: 131 },
          { label: "Aria", value: 132 },
          { label: "Davis", value: 133 },
          { label: "Amber", value: 134 },
          { label: "Andrew", value: 135 },
          { label: "Ashley", value: 136 },
          { label: "Brandon", value: 137 },
          { label: "Brian", value: 138 },
          { label: "Christopher", value: 139 },
          // { label: "annie", value: 331 },
          // { label: "betty", value: 332 },
          // { label: "beth", value: 333 },
          // { label: "cindy", value: 334 },
          // { label: "cally", value: 335 },
          // { label: "donna", value: 336 },
          // { label: "eva", value: 337 },
          // { label: "brian", value: 338 },
          // { label: "william", value: 339 },
        ],
      },
      {
        label: "日语",
        value: 3,
        children: [
          { label: "Nanami", value: 140 },
          { label: "Keita", value: 141 },
          { label: "Aoi", value: 142 },
          { label: "Daichi", value: 143 },
          { label: "Mayu", value: 144 },
          { label: "Naoki", value: 145 },
          { label: "Shiori", value: 146 },
          // { label: "tomoka", value: 341 },
          // { label: "tomoya", value: 342 },
        ],
      },
      {
        label: "韩语",
        value: 4,
        children: [
          { label: "SunHi", value: 150 },
          { label: "InJoon", value: 151 },
          { label: "BongJin", value: 152 },
          { label: "GookMin", value: 153 },
          { label: "Hyunsu", value: 154 },
          { label: "JiMin", value: 155 },
          { label: "SeoHyeon", value: 156 },
          { label: "SoonBok", value: 157 },
          { label: "YuJin", value: 158 },
          // { label: "Kyong", value: 351 },
        ],
      },
      {
        label: "西班牙语",
        value: 5,
        children: [
          { label: "Elvira", value: 160 },
          { label: "Alvaro", value: 161 },
          { label: "Abril", value: 162 },
          { label: "Arnau", value: 163 },
          { label: "Dario", value: 164 },
          { label: "Elias", value: 165 },
          { label: "Estrella", value: 166 },
          { label: "Irene", value: 167 },
          { label: "Laia", value: 168 },
          { label: "Lia", value: 169 },
          // { label: "camila", value: 361 },
        ],
      },
      {
        label: "法语",
        value: 6,
        children: [
          { label: "Denise", value: 171 },
          // { label: "clara", value: 371 },
        ],
      },
    ],
  },
  { label: "200", value: 200, children: LANGUAGE_DATA_part },
  {
    label: "300",
    value: 300,
    children: [
      {
        label: "普通话",
        value: 1,
        children: [
          //   { label: "zhixiaobai", value: 321 },
          //   { label: "zhixiaoxia", value: 322 },
          { label: "zhixiaomei", value: 323 },
          { label: "zhigui", value: 324 },
          { label: "zhishuo", value: 325 },
          { label: "aixia", value: 326 },
          { label: "xiaoyun", value: 327 },
          { label: "xiaogang", value: 328 },
          { label: "ruoxi", value: 329 },
        ],
      },
      {
        label: "英语",
        value: 2,
        children: [
          { label: "annie", value: 331 },
          { label: "betty", value: 332 },
          { label: "beth", value: 333 },
          { label: "cindy", value: 334 },
          { label: "cally", value: 335 },
          { label: "donna", value: 336 },
          { label: "eva", value: 337 },
          { label: "brian", value: 338 },
          { label: "william", value: 339 },
        ],
      },
    ],
  },
];

const VOICE_TPYE_NO = {
  label: "300",
  value: 300,
  children: [
    {
      label: "粤语",
      value: 0,
      children: [
        { label: "shanshan", value: 311 },
        { label: "jiajia", value: 312 },
        { label: "taozi", value: 313 },
        { label: "kelly", value: 314 },
      ],
    },
    {
      label: "普通话",
      value: 1,
      children: [
        { label: "zhixiaobai", value: 321 },
        { label: "zhixiaoxia", value: 322 },
        { label: "zhixiaomei", value: 323 },
        { label: "zhigui", value: 324 },
        { label: "zhishuo", value: 325 },
        { label: "aixia", value: 326 },
        { label: "xiaoyun", value: 327 },
        { label: "xiaogang", value: 328 },
        { label: "ruoxi", value: 329 },
      ],
    },
    {
      label: "英语",
      value: 2,
      children: [
        { label: "annie", value: 331 },
        { label: "betty", value: 332 },
        { label: "beth", value: 333 },
        { label: "cindy", value: 334 },
        { label: "cally", value: 335 },
        { label: "donna", value: 336 },
        { label: "eva", value: 337 },
        { label: "brian", value: 338 },
        { label: "william", value: 339 },
      ],
    },
    {
      label: "日语",
      value: 3,
      children: [
        { label: "tomoka", value: 341 },
        { label: "tomoya", value: 342 },
      ],
    },
    {
      label: "韩语",
      value: 4,
      children: [{ label: "Kyong", value: 351 }],
    },
    {
      label: "西班牙语",
      value: 5,
      children: [{ label: "camila", value: 361 }],
    },
    {
      label: "法语",
      value: 6,
      children: [{ label: "clara", value: 371 }],
    },
  ],
};

// 视频人声的标注
export const MARK = [
  {
    label: "标注",
    value: 0,
    // disabled: true,
    children: [VOICE_TPYE[0], VOICE_TPYE[2]],
  },
  {
    label: "不标注",
    value: 1,
    children: [VOICE_TPYE[0], VOICE_TPYE[1], VOICE_TPYE_NO],
  },
];

// 背景音乐
export const BGM = [
  { label: "禁用", value: -1 },
  { label: "随机", value: 0 },
];

// 翻译语言
export const TANSLATE_LANGUAGE = [
  { value: 0, label: "无" },
  { value: 1, label: "中文" },
  { value: 3, label: "英文" },
  { value: 4, label: "日文" },
  { value: 5, label: "韩文" },
  { value: 6, label: "西文" },
  { value: 7, label: "法文" },
];

// 视频语速
export const SPEECH_RATE = [
  { label: "1.0 倍速", value: 0 },
  { label: "1.2 倍速", value: 100 },
  { label: "1.4 倍速", value: 200 },
  { label: "1.6 倍速", value: 300 },
  { label: "1.8 倍速", value: 400 },
  { label: "2.0 倍速", value: 500 },
];

//视频进度抽屉
export const VIDEO_TASK = [
  { value: "video", label: "视频" },
  { value: "dubbing", label: "字幕" },
];

// 主题颜色
// 暗黑主题样式
export const STYLES_NIGHT = {
  myHomeSideBgcColor: "rgb(0, 0, 0)",
  myHomeSideTitleImg: "rgb(21, 22, 26)",
  myHomeSideItemActive: "rgb(29, 30, 35)",
  myHomeSideItemText: "rgb(166, 166, 166)",
  myBackgroundColor: "rgb(0, 0, 0)",
  myBoxColor: "rgb(29, 30, 35)",
  myWordColor: "rgb(255, 255, 255)",
  myButtonColor: "rgb(40, 43, 47)",
  myButtonAllowColor: "rgb(105, 4, 233)",
  myButtonTextColor: "rgb(160, 174, 192)",
  myCardBgcColor: "rgb(65, 64, 64)",
  mySelectOptionSelectedBgcColor: "rgb(94, 94, 94)",
  mySelectOPtionBgcColor: "rgb(40, 43, 47)",
  mySelectTextPlaceholderColor: "rgb(200, 200, 200)",
  mySelectShodowColor: "0 0 15px rgb(100,100,100)",
  myTimePickerSplitColor: "rgb(255,255,255,0.3)",
  myUploadBorderColor: "rgb(100, 100, 100)",
  myUploadBorderHoverColor: "rgb(255,255,255)",
};

// 光照主题样式
export const STYLES_LiGHT = {
  myHomeSideBgcColor: "rgb(245, 245, 245)",
  myHomeSideTitleImg: "rgb(245, 245, 245)",
  myHomeSideItemActive: "rgb(210, 210, 210)",
  myHomeSideItemText: "rgb(166, 166, 166)",
  myBackgroundColor: "rgb(245, 245, 245)",
  myBoxColor: "rgb(255, 255, 255)",
  myWordColor: "rgb(0, 0, 0)",
  myButtonColor: "rgba(168, 168, 168, 0.2)",
  myButtonAllowColor: "rgb(119, 119, 119)",
  myButtonTextColor: "rgb(160, 174, 192)",
  myCardBgcColor: "rgb(255, 255, 255)",
  mySelectOptionSelectedBgcColor: "rgb(202, 202, 202)",
  mySelectOPtionBgcColor: "rgb(255, 255, 255)",
  mySelectTextPlaceholderColor: "rgb(120, 120, 120)",
  mySelectShodowColor: "0 0 15px rgb(104, 104, 104)",
  myTimePickerSplitColor: "rgba(5, 5, 5, 0.06)",
  myUploadBorderColor: "rgb(200, 200, 200)",
  myUploadBorderHoverColor: "rgb(0,0,0)",
};
