import api, { audioApi } from "../api/api";

interface PostAudioApiProps {
  text: string;
  voice_id: number;
  file_format: string;
  response_format: string;
  speed: number;
}

export interface getVoiceApiResponeProps {
  comment: string;
  gender: string;
  language: string;
  style: string;
  voice_id: number;
  voice_name: string;
}

export const getVoiceApi = async (): Promise<{
  data: { result: getVoiceApiResponeProps[] };
}> => {
  return await audioApi.post("/api/speech/list");
};

export const postAudioDataApi = async (params: PostAudioApiProps) => {
  return await api.post("/voice/audition", params);
};
