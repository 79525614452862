import { TANSLATE_LANGUAGE } from "@/lib/constants";
import { Select, Tag } from "antd";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { SelectSubtitleProps } from "./props";
import { useEffect, useState } from "react";

// 字幕(下)的数据
// 上下字幕数据相同，使用字幕一（上）的最后一项的value作为两个字幕数据之间的差距。
export const subtitleLen =
  TANSLATE_LANGUAGE[TANSLATE_LANGUAGE.length - 1].value + 1;

export const SelectSubtitle = (props: SelectSubtitleProps) => {
  const [value, setValue] = useState<number[]>([]);

  const onChange = (newValue: number[]) => {
    if (newValue.length === 1 && newValue[0] >= subtitleLen) {
      setValue([]);
      props.onChange?.([]);
    } else {
      setValue(newValue);
      props.onChange?.(newValue);
    }
  };

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  return (
    <Select
      mode="multiple"
      style={{
        width: "120px",
      }}
      showSearch={false}
      placeholder={"选择字幕"}
      tagRender={(props: CustomTagProps) => {
        const { label } = props;
        return (
          <Tag
            style={{
              marginRight: 1,
            }}
          >
            <span>{label}</span>
          </Tag>
        );
      }}
      value={props.value ?? value}
      onChange={onChange}
      options={[
        {
          label: "字幕（一）",
          options: TANSLATE_LANGUAGE.filter((item) => {
            if (value[0] !== undefined) {
              return value[0] === item.value;
            }
            return true;
          }).map((item) => {
            return {
              value: item.value,
              label: item.label,
            };
          }),
        },
        {
          label: "字幕（二）",
          options: TANSLATE_LANGUAGE.filter((item) => {
            if (value[1] !== undefined) {
              return value[1] === item.value + subtitleLen;
            }
            return value[0] !== 0;
          }).map((item) => {
            let disabled = false;
            if (!value[0]) {
              disabled = true;
            }
            return {
              value: item.value + subtitleLen,
              label: item.label,
              disabled: disabled,
            };
          }),
        },
      ]}
    />
  );
};
