import { Route, Routes } from "react-router-dom";
import CreateImageTextVideo from "../../pages/Image-Text/createImageTextVideo";
import { Check } from "../Check";

const ImagepptToVideo = () => {
  return (
    <Routes>
      <Route
        path="createPPTTask"
        element={<Check element={<CreateImageTextVideo />} />}
      ></Route>
    </Routes>
  );
};

export default ImagepptToVideo;
