import { FC, useRef, useState } from "react";
import {
  Button,
  Card,
  ConfigProvider,
  Divider,
  Flex,
  Input,
  TimePicker,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import styles from "./index.module.scss";
import useAction from "./hooks";
import { CardItemProp, TempCardProp, TextSelected } from "./props";
import { useAigcVideoStore, useVideoStore } from "@/store";
import ReactQuill from "react-quill";
import {
  ButtonEnSpellRead,
  ButtonNormal,
  ButtonNumContinuouRead,
  ButtonNumSpellRead,
  ButtonNumSpellRead100,
  InputTextMarkDubbing,
} from "@/components";

const { TextArea } = Input;

const TranslateTimeCard: FC<TempCardProp> = (props) => {
  const [myTheme] = useAigcVideoStore((state) => [state.myTheme]);
  const [videoTranslateData] = useVideoStore((state) => [
    state.videoTranslateData,
  ]);

  return (
    <>
      <Card
        className={styles["card-content"]}
        title={<div>{props.videoName}</div>}
        bodyStyle={{
          display: "grid",
          paddingTop: "0",
          paddingBottom: "0",
          gap: "20px",
          maxHeight: "750px",
          overflow: "hidden",
        }}
      >
        <Flex className={styles["card-body"]} gap={20} vertical>
          {props.audioList.map((item, index, arr) => {
            return (
              <CardItem
                theme={myTheme}
                key={item.id}
                item={item}
                index={index}
                arr={arr}
                termValue={videoTranslateData.model}
                voiceList={videoTranslateData.voiceList}
                audioList={props.audioList}
                audioListChange={props.audioListChange}
              />
            );
          })}
        </Flex>
        <div className={styles["add-button"]}>
          <Button
            className={`${styles["button"]} ${
              myTheme && styles["button-bgc-light"]
            }`}
            onClick={() => {
              const len = props.audioList.length;
              const lastAudioItem = props.audioList[len - 1];
              const newList = {
                text: "",
                start: lastAudioItem.new.end,
                end: dayjs(lastAudioItem.new.end, "HH:mm:ss")
                  .add(1, "second")
                  .format("HH:mm:ss"),
              };
              const oldList = {
                text: "",
                start: lastAudioItem.old.end,
                end: dayjs(lastAudioItem.old.end, "HH:mm:ss")
                  .add(1, "second")
                  .format("HH:mm:ss"),
              };

              const tempAudio = {
                id: lastAudioItem.id + 1,
                state: true,
                new: newList,
                old: oldList,
              };
              props.audioListChange([...props.audioList, tempAudio]);
            }}
          >
            +
          </Button>
        </div>
      </Card>
    </>
  );
};

const CardItem: FC<CardItemProp> = (props) => {
  const { theme, voiceList, item, index, arr, termValue } = props;
  const {
    newTimeChange,
    oldimeChange,
    editNewText,
    editOldTextArea,
    delAudio,
    tanslateText,
  } = useAction(props);

  const inputRef = useRef<ReactQuill>(null);
  const [textSelected, textSelectedAction] = useState<TextSelected>();

  return (
    <Card
      key={item.id}
      type="inner"
      className={`${styles["card-item"]} ${
        theme ? styles["card-item-light"] : styles["card-item-night"]
      }`}
      extra={
        <>
          {voiceList[0] === 0 && (
            <div style={{ display: "flex", gap: 20 }}>
              <ButtonNormal position={textSelected} refs={inputRef} />
              <ButtonEnSpellRead position={textSelected} refs={inputRef} />

              {voiceList[1] !== 100 && (
                <ButtonNumSpellRead position={textSelected} refs={inputRef} />
              )}
              {voiceList[1] === 100 && (
                <ButtonNumSpellRead100
                  position={textSelected}
                  refs={inputRef}
                />
              )}
              <ButtonNumContinuouRead
                position={textSelected}
                refs={inputRef}
                disabled={voiceList[1] === 100}
              />
              {arr.length !== 1 && (
                <CloseOutlined
                  className={styles["delete"]}
                  onClick={() => delAudio(item.id)}
                />
              )}
            </div>
          )}
        </>
      }
      title={
        <>
          <span className={styles["index"]}>{index + 1}、</span>
        </>
      }
    >
      <div className={styles["data-body"]}>
        <TimePicker.RangePicker
          allowClear={false}
          inputReadOnly
          status={item.state ? "" : "error"}
          className={`${styles["time-new"]} ${
            item.state ? "" : styles["time-new-err"]
          }`}
          value={[
            dayjs(item.new.start, "HH:mm:ss"),
            dayjs(item.new.end, "HH:mm:ss"),
          ]}
          onChange={(time, timeString) => newTimeChange(timeString, index)}
        />
        {/* <TextArea
          rows={1}
          value={item.new.text}
          className={styles["textarea-new"]}
          bordered={false}
          onChange={(e) => editNewText(item.id, e.target.value)}
        /> */}
        <InputTextMarkDubbing
          ref={inputRef}
          placeholder="输入文本（建议一句话不超过200个字）"
          value={item.new.text}
          onChange={(value, text) => {
            editNewText(item.id, value);
          }}
          onChangeSelection={(selcet) => {
            textSelectedAction(() => selcet);
          }}
        />
      </div>
      <Divider dashed className={styles["divider"]} />
      <div className={styles["data-body"]}>
        <ConfigProvider
          theme={{
            components: {
              DatePicker: {
                colorText: "rgb(165, 163, 163)",
              },
            },
          }}
        >
          <TimePicker.RangePicker
            allowClear={false}
            inputReadOnly
            className={styles["time-old"]}
            value={[
              dayjs(item.old.start, "HH:mm:ss"),
              dayjs(item.old.end, "HH:mm:ss"),
            ]}
            onChange={(time, timeString) => oldimeChange(timeString, index)}
          />
        </ConfigProvider>
        <TextArea
          rows={1}
          className={styles["textarea-old"]}
          bordered={false}
          value={item.old.text}
          onChange={(e) => {
            editOldTextArea(item.id, e.target.value);
            tanslateText({
              text: e.target.value,
              voice: Number(voiceList[voiceList.length - 1]),
              model:
                termValue !== undefined && termValue[0] !== "无"
                  ? termValue
                  : [],
              provider_id: 1,
            });
          }}
        />
      </div>
    </Card>
  );
};

export default TranslateTimeCard;
