import { useRequest, useUnmount } from "ahooks";
import { useEffect, useRef, useState } from "react";
import { getVideoDataApi } from "../../../services";
import { useAigcVideoStore, useVideoStore } from "@/store";
import {
  changeColor,
  richTextCheck,
  textIsFail,
  textReplace,
  useThemeStyles,
} from "@/components";
import { message } from "antd";
import { TextSelected, subtitleProp } from "./props";
// 字幕数据差距
import { subtitleLen } from "@/components";
import ReactQuill from "react-quill";
import { useVideoGenerateStateStore } from "@/store/videoGenerateStateLocal";

const useAction = () => {
  // 存储视频
  const [setTextVideoList] = useAigcVideoStore((state) => [
    state.setTextVideoList,
  ]);

  // 存储视频进度列表
  const [createPageTask, setCreatePageTask] = useVideoGenerateStateStore(
    (state) => [state.createPageTask, state.setCreatePageTask]
  );

  // 全局变量
  const [createPageList, settingStyleData, setCreatePageList] = useVideoStore(
    (state) => [
      state.createPageList,
      state.settingStyleData,
      state.setCreatePageList,
    ]
  );

  const { myTheme } = useThemeStyles();
  const inputRef = useRef<ReactQuill>(null);
  const [textSelected, textSelectedAction] = useState<TextSelected>();
  const [videoName, videoNameAction] = useState<string>(createPageList.title);
  const [inputText, inputTextAction] = useState<string>(createPageList.text);
  const [loading, setLoading] = useState(false);
  const [textPassFlag, textPassFlagAction] = useState<boolean>(true);
  const [textMarkShow, textMarkShowAction] = useState(false);
  const [voiceNum, voiceNumAction] = useState<Number>(0);
  const [open, setOpen] = useState(false);
  const [bgmNum, bgmNumAction] = useState<number>(
    settingStyleData.createTask.text_video_bgc_music
  );
  const [languageNum, languageNumAction] = useState<number[] | undefined>(
    settingStyleData.createTask.text_video_voice
  );
  const [subtitle, subtitleAction] = useState<subtitleProp>({
    main: settingStyleData.createTask.text_video_subtitle[0],
    second: settingStyleData.createTask.text_video_subtitle[1],
  });
  const [subtitleSelectValue, subtitleSelectValueAction] = useState<number[]>(
    settingStyleData.createTask.text_video_subtitle
  );
  const [speechRate, speechRateAction] = useState<number>(
    settingStyleData.createTask.text_video_speaking_speed
  );

  const onChangeSpeakingSpeed = (value: number) => {
    speechRateAction(value);
  };

  const onChangeLanguage = (valueList?: number[]) => {
    languageNumAction(valueList);

    if (valueList) {
      voiceNumAction(() => Number(valueList[1]));
    }

    if (valueList && valueList[0] === 0) {
      textMarkShowAction(() => true);
    } else {
      textMarkShowAction(() => false);
      const text = inputRef.current?.getEditor().getText();
      if (text) {
        changeColor(
          "",
          false,
          { text: text, start: 0, length: text?.length },
          inputRef
        );
      }
    }
  };

  const onChangeBgm = (value: number) => {
    bgmNumAction(value);
  };

  const onChangeSubtitle = (value: number[]) => {
    subtitleSelectValueAction(value);

    if (value[0] === 0) {
      subtitleAction({ main: value[0], second: 0 });
    } else if (value[0] >= subtitleLen) {
      subtitleSelectValueAction(() => []);
      subtitleAction({
        main: undefined,
        second: undefined,
      });
    } else {
      subtitleAction({
        main: value[0],
        second: value[1] ? value[1] : undefined,
      });
    }
  };

  const onChangeText = (value: string) => {
    inputTextAction(() => value);
    if (inputRef.current) {
      if (textIsFail(inputRef.current?.getEditor().getText())) {
        textPassFlagAction(() => false);
      } else {
        textPassFlagAction(() => true);
      }
    }
  };

  // 文本处理
  const formatText = () => {
    const text = richTextCheck(inputRef);
    if (text) {
      inputTextAction(() => text);
    }
    if (inputRef.current) {
      if (textIsFail(inputRef.current?.getEditor().getText())) {
        textPassFlagAction(() => false);
      } else {
        textPassFlagAction(() => true);
      }
    }
  };

  // 检查是否配置必选项
  const generateCheck = () => {
    if (
      inputText.length &&
      videoName.length &&
      subtitleSelectValue.length &&
      typeof languageNum !== "undefined" &&
      typeof bgmNum !== "undefined" &&
      typeof speechRate !== "undefined"
    ) {
      return true;
    }
    return false;
  };

  const generateVideoRequest = useRequest(getVideoDataApi, {
    manual: true,
    onSuccess(result) {
      setLoading(false);
      setCreatePageTask({
        ...createPageTask,
        video: {
          tasks: [
            ...createPageTask.video.tasks,
            {
              id: result.data.task_id,
              name: videoName,
              isGenerate: false,
              state: 0,
              time: result.data.task_time,
            },
          ],
        },
      });
      message.success("生成成功");
    },
    onError(e) {
      setLoading(false);
      message.error("生成失败");
    },
  });

  const generateVideo = () => {
    setLoading(true);

    // 处理用户在定时器生效之前的点击生成的问题
    if (
      inputRef.current &&
      textIsFail(inputRef.current?.getEditor().getText())
    ) {
      textPassFlagAction(() => false);
      setLoading(false);
      return;
    }
    generateVideoRequest.run({
      source: [
        {
          text: textReplace(inputText),
          voice: languageNum?.[languageNum?.length - 1] as number,
          video: "",
        },
      ],
      bgm: bgmNum as number,
      subtitles: {
        main: typeof subtitle.main !== "undefined" ? subtitle.main : 0,
        second:
          typeof subtitle.second !== "undefined" && subtitle.second !== 0
            ? subtitle.second - subtitleLen
            : 0,
      },
      speech_rate: typeof speechRate !== "undefined" ? speechRate : 0,
    });
  };

  // 视频生成状态
  const generateVideoTask = (url: string, name: string) => {
    setTextVideoList(url, name);
  };

  useUnmount(() => {
    setCreatePageList({
      ...createPageList,
      title: videoName,
      text: inputText,
    });
  });

  useEffect(() => {
    bgmNumAction(settingStyleData.createTask.text_video_bgc_music);
    languageNumAction(settingStyleData.createTask.text_video_voice);
    subtitleAction({
      main: settingStyleData.createTask.text_video_subtitle[0],
      second: settingStyleData.createTask.text_video_subtitle[1],
    });
    subtitleSelectValueAction(settingStyleData.createTask.text_video_subtitle);
    speechRateAction(settingStyleData.createTask.text_video_speaking_speed);
  }, [settingStyleData.createTask]);

  return {
    myTheme,
    inputRef,
    textSelected,
    inputText,
    loading,
    videoName,
    subtitle,
    subtitleSelectValue,
    textPassFlag,
    textMarkShow,
    voiceNum,
    open,
    languageNum,
    bgmNum,
    speechRate,
    textSelectedAction,
    generateVideo,
    videoNameAction,
    onChangeText,
    onChangeLanguage,
    onChangeBgm,
    onChangeSubtitle,
    onChangeSpeakingSpeed,
    generateCheck,
    formatText,
    setOpen,
    generateVideoTask,
  };
};

export default useAction;
