import { FC } from "react";
import {
  ButtonSelf,
  InputText,
  LanguageSelect,
  CascaderLanguage,
  CardSelf,
} from "@/components";
import useAction from "./hooks";
import styles from "./index.module.scss";
import { SPEECH_RATE } from "@/lib/constants";
import SiderButton from "@/components/SiderButton";
import VideoRate from "@/components/VideoRate";
import { Input } from "antd";

const TextToAudio: FC = () => {
  const {
    text,
    loading,
    tasksOpen,
    videoName,
    languageNum,
    speechRate,
    videoNameAction,
    onChangeTextArea,
    onChangeLanguage,
    onChangeSpeakingSpeed,
    generateAudio,
    generateCheck,
    setTasksOpen,
    getVideoData,
    getDubbingData,
  } = useAction();
  return (
    <>
      <CardSelf
        className={styles["text-content"]}
        title={
          <Input
            value={videoName}
            placeholder="请输入视频名称"
            bordered={false}
            classNames={{ input: styles["name"] }}
            onChange={(e) => videoNameAction(e.target.value)}
          />
        }
      >
        <InputText
          placeholder="输入配音文本"
          value={text}
          onChange={onChangeTextArea}
          className={styles["input-textarea"]}
        ></InputText>
        <div className={styles["button-content"]}>
          <LanguageSelect
            data={SPEECH_RATE}
            placeholder="选择语速"
            value={speechRate}
            onChange={onChangeSpeakingSpeed}
          />
          <CascaderLanguage
            value={languageNum.valueList}
            onChange={onChangeLanguage}
          />
          <ButtonSelf
            btnName="生成"
            onClick={generateAudio}
            flag={generateCheck()}
            loading={loading}
          ></ButtonSelf>
        </div>
      </CardSelf>

      <SiderButton onClick={() => setTasksOpen(true)} />
      <VideoRate
        title="创建视频生成任务"
        open={tasksOpen}
        defaultValue="dubbing"
        onClose={() => setTasksOpen(false)}
        page="videoDubbingAudioTask"
        onVideoChange={getVideoData}
        onAudioChange={getDubbingData}
      />
    </>
  );
};

export default TextToAudio;
