import { useEffect, useState } from "react";
import { dataAwaitProp, subtitleProp } from "./props";
import { useRequest, useUnmount } from "ahooks";
import { getVideoSimilarDataMergeApi } from "@/services";
import { message } from "antd";
import { useVideoStore } from "@/store";
import { subtitleLen, useThemeStyles } from "@/components";
import { useVideoGenerateStateStore } from "@/store/videoGenerateStateLocal";

const useAction = () => {
  // 本地生成的视频存储,主题颜色
  const { myTheme } = useThemeStyles();
  // 全局状态管理
  const [
    createSelectPageDataAwaitList,
    settingStyleData,
    setCreateSelectPageDataAwaitList,
  ] = useVideoStore((state) => [
    state.createSelectPageDataAwaitList,
    state.settingStyleData,
    state.setCreateSelectPageDataAwaitList,
  ]);

  // 存储视频进度列表
  const [createSelectPageTask, setCreateSelectPageTask] =
    useVideoGenerateStateStore((state) => [
      state.createSelectPageTask,
      state.setCreateSelectPageTask,
    ]);

  const [videoDataAwait, videoDataAwaitAction] = useState<dataAwaitProp[]>(
    () => createSelectPageDataAwaitList.videoDataAwait
  );

  const [loading, loadingAction] = useState<boolean>(false);
  const [languageNum, languageNumAction] = useState<number[] | undefined>(
    settingStyleData.createSelectTask.text_select_video_voice
  );
  const [bgmNum, bgmNumAction] = useState<number>(
    settingStyleData.createSelectTask.text_select_video_bgc_music
  );
  const [subtitle, subtitleAction] = useState<subtitleProp>({
    main: settingStyleData.createSelectTask.text_select_video_subtitle[0],
    second: settingStyleData.createSelectTask.text_select_video_subtitle[1],
  });
  const [subtitleSelectValue, subtitleSelectValueAction] = useState<number[]>(
    settingStyleData.createSelectTask.text_select_video_subtitle
  );
  const [speechRate, speechRateAction] = useState<number>(
    settingStyleData.createSelectTask.text_select_video_speaking_speed
  );
  const [videoListName, videoListNameAction] = useState<string>("");

  useUnmount(() => {
    setCreateSelectPageDataAwaitList({
      videoDataAwait: videoDataAwait,
    });
  });

  const onChangeSpeakingSpeed = (value: number) => {
    speechRateAction(value);
  };

  const onChange = (valueList?: number[]) => {
    languageNumAction(valueList);
  };

  const onChangeBgm = (value: number) => {
    bgmNumAction(value);
  };

  const onChangeSubtitle = (value: number[]) => {
    subtitleSelectValueAction(value);

    if (value[0] === 0) {
      subtitleAction({ main: value[0], second: 0 });
    } else if (value[0] >= subtitleLen) {
      subtitleSelectValueAction(() => []);
      subtitleAction({
        main: undefined,
        second: undefined,
      });
    } else {
      subtitleAction({
        main: value[0],
        second: value[1] ? value[1] : undefined,
      });
    }
  };

  // 检查是否填写必选项
  const generateCheck = () => {
    if (
      subtitleSelectValue.length &&
      typeof speechRate !== "undefined" &&
      typeof bgmNum !== "undefined" &&
      typeof languageNum !== "undefined"
    ) {
      return true;
    }
    return false;
  };

  const generateVideo = (videoName: string) => {
    loadingAction(true);
    videoListNameAction(videoName);
    const list = videoDataAwait.map((item) => ({
      ...item,
      voice: languageNum?.[languageNum?.length - 1] as number,
    }));
    generateVideoRequest.run({
      source: list,
      bgm: bgmNum as number,
      subtitles: {
        main: typeof subtitle.main !== "undefined" ? subtitle.main : 0,
        second:
          typeof subtitle.second !== "undefined" && subtitle.second !== 0
            ? subtitle.second - subtitleLen
            : 0,
      },
      speech_rate: typeof speechRate !== "undefined" ? speechRate : 0,
    });
  };

  const generateVideoRequest = useRequest(getVideoSimilarDataMergeApi, {
    manual: true,
    onSuccess(result) {
      loadingAction(false);
      message.success("success");
      if (!!result.data.task_id) {
        setCreateSelectPageTask({
          ...createSelectPageTask,
          video: {
            tasks: [
              ...createSelectPageTask.video.tasks,
              {
                id: result.data.task_id,
                name: videoListName,
                isGenerate: false,
                state: 0,
                time: result.data.task_time,
              },
            ],
          },
        });
      }
    },
    onError(e) {
      loadingAction(false);
      message.error("error");
    },
  });

  useEffect(() => {
    bgmNumAction(settingStyleData.createSelectTask.text_select_video_bgc_music);
    languageNumAction(
      settingStyleData.createSelectTask.text_select_video_voice
    );
    subtitleAction({
      main: settingStyleData.createSelectTask.text_select_video_subtitle[0],
      second: settingStyleData.createSelectTask.text_select_video_subtitle[1],
    });
    subtitleSelectValueAction(
      settingStyleData.createSelectTask.text_select_video_subtitle
    );
    speechRateAction(
      settingStyleData.createSelectTask.text_select_video_speaking_speed
    );
  }, [settingStyleData.createSelectTask]);

  return {
    videoDataAwait,
    loading,
    subtitle,
    subtitleSelectValue,
    myTheme,
    bgmNum,
    languageNum,
    speechRate,
    videoDataAwaitAction,
    generateVideo,
    onChange,
    onChangeBgm,
    onChangeSubtitle,
    onChangeSpeakingSpeed,
    generateCheck,
  };
};

export default useAction;
