import styles from "./index.module.scss";
import useAction from "./hooks";
import {
  InputText,
  ButtonSelf,
  InputTextMark,
  ButtonNormal,
  ButtonEnSpellRead,
  ButtonNumSpellRead,
  ButtonNumContinuouRead,
  CardSelf,
} from "@/components";
import { Layout, Input, Divider } from "antd";
import DataLayout from "../components/dataLayout";
import SiderButton from "@/components/SiderButton";
import VideoRate from "@/components/VideoRate";

const { Content } = Layout;

const CreateSelectTask = () => {
  const {
    inputRef,
    textSelected,
    inputText,
    videoData,
    videoName,
    loading,
    open,
    setOpen,
    onChangeText,
    textSelectedAction,
    deleteVideoData,
    inputTextAction,
    generateVideo,
    videoNameAction,
    generateCheck,
    generateVideoTask,
  } = useAction();

  return (
    <>
      <Layout className={styles["root"]}>
        <CardSelf
          className={styles["headerInputContent"]}
          title={
            <Input
              className={styles["videoName"]}
              value={videoName}
              bordered={false}
              placeholder="请输入视频名称"
              onChange={({ target }) => videoNameAction(target.value)}
            />
          }
        >
          {/* <InputText
          value={inputText}
          onChange={inputTextAction}
          placeholder="输入文本"
          maxLength={200}
        /> */}
          <Divider />
          <InputTextMark
            ref={inputRef}
            height={84}
            placeholder="输入文本（建议一句话不超过200个字）"
            value={inputText}
            onChange={onChangeText}
            onChangeSelection={(selcet) => {
              textSelectedAction(() => selcet);
            }}
          />
          <Content className={styles["btnInputAll"]}>
            <div className={styles["showCount"]}>{`${
              // 空字符时为长度为1
              inputRef.current?.getEditor().getText().length &&
              inputRef.current?.getEditor().getText().length - 1
                ? inputRef.current?.getEditor().getText().length - 1
                : 0
            } / 200 words`}</div>
            <div className={styles["btn"]}>
              {/* <ButtonNormal position={textSelected} refs={inputRef} />
            <ButtonEnSpellRead position={textSelected} refs={inputRef} />
            <ButtonNumSpellRead position={textSelected} refs={inputRef} />
            <ButtonNumContinuouRead position={textSelected} refs={inputRef} /> */}
              <ButtonSelf
                btnName="推荐"
                loading={loading}
                onClick={generateVideo}
                flag={generateCheck()}
              />
            </div>
          </Content>
        </CardSelf>
        <DataLayout
          inputText={inputText}
          videoName={videoName}
          videoData={videoData}
          onSelectDel={deleteVideoData}
        />
      </Layout>
      <SiderButton onClick={() => setOpen(true)} />
      <VideoRate
        title="创建视频生成任务"
        open={open}
        onClose={() => setOpen(false)}
        page="createSelectPageTask"
        onVideoChange={generateVideoTask}
      />
    </>
  );
};

export default CreateSelectTask;
