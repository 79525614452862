import { Route, Routes } from "react-router-dom";
import CreateTask from "../../pages/Video-Text/createTask";
import CreateSelectTask from "../../pages/Video-Text/createSelectTask";
import { Check } from "../Check";

const TextToVideoRoute = () => {
  return (
    <Routes>
      <Route
        path="createTask"
        element={<Check element={<CreateTask />} />}
      ></Route>
      <Route
        path="createSelectTask"
        element={<Check element={<CreateSelectTask />} />}
      ></Route>
    </Routes>
  );
};

export default TextToVideoRoute;
