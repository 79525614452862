import { Button, Checkbox, Drawer, List, Select, Space, Tooltip } from "antd";
import styles from "./index.module.scss";
import { FC, memo } from "react";
import { VideoRateProps } from "./props";
import useAction from "./hooks";
import PollingItem from "./PollingItem";
import GenerateButton from "./GnerateButton";
import { useThemeStyles } from "../theme";
import { STATE } from "@/lib/enums";
import GnerateTime from "./GnerateTime";

const STATE_LIST = [
  { style: "btn-state-wait", state: "等待中" },
  { style: "btn-state-wait", state: "处理中" },
  { style: "btn-state-isdone", state: "已生成" },
  { style: "btn-state-err", state: "失败" },
];

const VideoRate: FC<VideoRateProps> = memo((props) => {
  const {
    checkAll,
    checkedList,
    taskList,
    options,
    deleteFlag,
    selectValue,
    setDeleteFlag,
    onCheckAllChange,
    onCheckChange,
    setSelectValue,
    generateVideoTask,
    onChangeState,
    deleteTask,
  } = useAction(props);
  const { myTheme } = useThemeStyles();

  return (
    <Drawer
      styles={{ header: { paddingLeft: "13px" } }}
      destroyOnClose
      title={
        <Select
          style={{ flex: 1, minWidth: 100 }}
          allowClear={false}
          defaultValue={props.defaultValue || options[0]}
          options={options}
          value={selectValue}
          onChange={(value) => {
            setSelectValue(value as string);
          }}
        />
      }
      closeIcon={false}
      onClose={props.onClose}
      open={props.open}
      width={420}
      extra={
        <Space>
          {!deleteFlag && (
            <Button
              type="primary"
              danger
              onClick={() => {
                setDeleteFlag(true);
              }}
            >
              删除
            </Button>
          )}
          {!!deleteFlag && (
            <>
              <Button
                className={`${myTheme ? styles["btn"] : styles["btn-black"]}`}
                onClick={() => {
                  setDeleteFlag(false);
                  onCheckAllChange(false);
                }}
              >
                取消
              </Button>
              <Button
                type="primary"
                className={styles["btn-ok"]}
                onClick={deleteTask}
              >
                确认
              </Button>
            </>
          )}
        </Space>
      }
    >
      <Checkbox
        className={`${styles["checkboxAll"]} ${
          !deleteFlag && styles["show-btn-opacity"]
        }`}
        checked={checkAll}
        onChange={(e) => onCheckAllChange(e.target.checked)}
      >
        全选
      </Checkbox>

      <Checkbox.Group
        style={{ width: "100%", display: "block" }}
        value={checkedList}
        onChange={(newCheckedList) => onCheckChange(newCheckedList as string[])}
      >
        <List
          itemLayout="horizontal"
          dataSource={taskList[selectValue].tasks}
          renderItem={(item) => {
            return (
              <div className={styles["task"]}>
                <div style={{ display: "flex", gap: 10, width: "60%" }}>
                  <Checkbox
                    className={`${!deleteFlag && styles["show-btn-opacity"]}`}
                    value={item.id}
                  />
                  <div className={styles["video-name-text"]}>
                    <Tooltip title={item.name}>
                      <span>{!!item.name ? item.name : "未命名视频"}</span>
                    </Tooltip>
                  </div>
                </div>

                {(item.state === STATE.WAIT ||
                  item.state === STATE.PROCESS) && (
                  <GnerateTime time={item.time} />
                )}

                <div className={styles["btn-state"]}>
                  {(item.state === STATE.WAIT ||
                    item.state === STATE.PROCESS) && (
                    <>
                      <PollingItem
                        id={item.id}
                        state={STATE_LIST[item.state].state}
                        className={STATE_LIST[item.state].style}
                        onChangeState={onChangeState}
                      />
                    </>
                  )}

                  {item.state === STATE.ERROR && (
                    <>
                      <span className={styles[STATE_LIST[item.state].style]}>
                        {STATE_LIST[item.state].state}
                      </span>
                    </>
                  )}

                  {item.state === STATE.DONE && (
                    <>
                      {item.isGenerate && (
                        <span className={styles[STATE_LIST[item.state].style]}>
                          {STATE_LIST[item.state].state}
                        </span>
                      )}
                      {!item.isGenerate && (
                        <GenerateButton
                          id={item.id}
                          onChangeState={onChangeState}
                          onDone={generateVideoTask}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            );
          }}
        />
      </Checkbox.Group>
    </Drawer>
  );
});

export default VideoRate;
