import { FC } from "react";
import { ConfigProvider, theme } from "antd";
import { useAigcVideoStore } from "@/store";
import { STYLES_LiGHT, STYLES_NIGHT } from "@/lib/constants";
import { MyConfigProviderProp, StylesProp } from "./props";

export const useThemeStyles = () => {
  const [myTheme] = useAigcVideoStore((state) => [state.myTheme]);
  const styles: StylesProp = myTheme ? STYLES_LiGHT : STYLES_NIGHT;
  return {
    myTheme,
    styles,
  };
};

export const MyConfigProvider: FC<MyConfigProviderProp> = (props) => {
  const { myTheme, styles } = useThemeStyles();
  return (
    <ConfigProvider
      theme={{
        algorithm: myTheme ? theme.defaultAlgorithm : theme.darkAlgorithm,
        token: {
          // colorBgContainer: styles["myBackgroundColor"],
          colorPrimary: "",
          colorTextPlaceholder: "rgb(160, 174, 192)",
          // 对话、select框选项
          colorBgElevated: styles["mySelectOPtionBgcColor"],
          // 第三级文本色，多选框标题颜色
          colorTextTertiary: "rgb(120, 120, 120)",
          // 禁用
          colorBgContainerDisabled: styles["myButtonColor"],
          colorTextDisabled: styles["myButtonTextColor"],
          // 经过/选中
          colorPrimaryActive: styles["myButtonTextColor"],
          colorPrimaryHover: "",
          // select框icon颜色
          colorTextQuaternary: "rgb(160, 174, 192)",
          // controlOutlineWidth: 0,
        },
        components: {
          Layout: {
            headerBg: styles["myHomeSideTitleImg"],
            siderBg: styles["myHomeSideBgcColor"],
            bodyBg: styles["myBackgroundColor"],
          },
          Menu: {
            // 菜单项
            itemColor: styles["myHomeSideItemText"],
            itemBg: "transparent",
            // 菜、子共有
            itemHoverColor: styles["myWordColor"],
            itemHoverBg: styles["myHomeSideItemActive"],
            itemActiveBg: styles["myHomeSideItemActive"],
            itemSelectedBg: styles["myHomeSideItemActive"],
            itemSelectedColor: styles["myWordColor"],
            // 子选项
            subMenuItemBg: "transparent",
            colorSplit: "transparent",
          },
          Card: {
            actionsBg: styles["myCardBgcColor"],
          },
          Upload: {
            colorBorder: styles["myUploadBorderColor"],
            colorPrimaryHover: styles["myUploadBorderHoverColor"],
            colorPrimary: "",
          },
          Button: {
            defaultBg: styles["mySelectOPtionBgcColor"],
            controlHeight: 34,
            primaryShadow: "",
            colorPrimary: styles["myButtonAllowColor"],
          },
          Select: {
            selectorBg: styles["mySelectOPtionBgcColor"],
            optionSelectedBg: styles["mySelectOptionSelectedBgcColor"],
            optionSelectedColor: styles["myWordColor"],
            optionActiveBg: styles["mySelectOptionSelectedBgcColor"],
            boxShadowSecondary: styles["mySelectShodowColor"],
            controlHeight: 34,
            // colorBorder: "transparent",
            // select框激活时的边框颜色
            colorPrimary: styles["myWordColor"],
            colorTextPlaceholder: styles["mySelectTextPlaceholderColor"],
          },
          Cascader: {
            optionSelectedBg: styles["mySelectOptionSelectedBgcColor"],
            controlItemBgHover: styles["mySelectOptionSelectedBgcColor"],
            colorSplit: styles["myTimePickerSplitColor"],
          },
          Switch: {
            colorTextLightSolid: styles["myWordColor"],
          },
          Input: {
            colorText: styles["myWordColor"],
            colorTextPlaceholder: "rgb(117, 117, 117);",
          },
          DatePicker: {
            cellHoverBg: "rgb(117, 117, 117)",
            controlItemBgActive: "rgb(200, 200, 200)",
            colorSplit: styles["myTimePickerSplitColor"],
            // 时间框激活颜色
            colorPrimary: styles["myWordColor"],
          },
          Divider: {
            marginLG: 0,
          },
          Pagination: {
            itemActiveBg: myTheme ? "" : "rgb(31, 31, 31)",
          },
        },
      }}
    >
      {props.children}
    </ConfigProvider>
  );
};
