import { useRequest } from "ahooks";
import { Button } from "antd";
import { FC } from "react";
import { ResultDataProps } from "../props";
import { generateVideoApi } from "@/services/generate-video-api";
import { useThemeStyles } from "@/components";
import styles from "./index.module.scss";

interface GenerateButtonProps {
  id: string;
  onChangeState: (id: string, state: number) => void;
  onDone: (data: ResultDataProps) => void;
}

const GenerateButton: FC<GenerateButtonProps> = ({
  id,
  onChangeState,
  onDone,
}) => {
  const { myTheme } = useThemeStyles();
  const { run, loading } = useRequest(generateVideoApi, {
    manual: true,
    onSuccess(result) {
      const tempData: ResultDataProps = result.data;
      onChangeState(tempData.task_id, tempData.status);
      onDone(tempData);
    },
  });

  const generate = (id: string) => {
    run({ task_id: id });
  };

  return (
    <Button
      loading={loading}
      type="text"
      className={`${styles["btn-generate"]} ${
        myTheme && styles["btn-generate-light"]
      }`}
      onClick={() => generate(id)}
    >
      生成
    </Button>
  );
};

export default GenerateButton;
